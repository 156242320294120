import React, { useEffect, useState } from 'react';
import { FormProvider } from 'react-hook-form';

import { useUpdateDocument } from '../../components/DocumentContentDetails/hooks/useUpdateDocument';
import { useValidateData } from './hooks/useValidateData';

import SubmissionFlowHeader from '@common/components/SubmissionFlowHeader/SubmissionFlowHeader';
import SubmissionFlowFooter from '@common/components/SubmissionFlowFooter/SubmissionFlowFooter';
import { usePrepareContentDetailsForm } from '../../components/DocumentContentDetails/hooks/usePrepareContentDetailsForm';
import { ValidationError } from './ValidationError';
import { ContentDetailsFormField } from './types';
import { DocumentType } from '@common/types';

import * as Styled from './styles';
import Tabs from '@common/components/Tabs/Tabs';
import { DocumentContent } from '../../components/DocumentContentDetails/DocumentContent';
import { HeaderBreadcrumb, Link } from '@common/components/SubmissionFlowHeader/styles';
import { useFeatureFlags } from '@common/hooks/useFeatureFlags';
import { DocumentVersionHistory } from '../../components/DocumentVersionHistory/DocumentVersionHistory';
import { documentTypeToCustomLabelMap } from '@common/constants';
import { IDocumentDto } from '@common/features/submission/types';
import {
  DocumentDetailsReadonlySections,
  DocumentProcessingDetails,
  DocumentProcessingFlow,
} from '../../components/DocumentContentDetails/types';
import { useGetAlternativeTextFile } from '@common/hooks/useGetAlternativeTextFile';
import { getDocumentAttributes } from '../../helpers';
import { useGeneratePrimaryFileDescription } from '../../components/DocumentContentDetails/hooks/useGenerateFileDescription';

export interface IDocumentDetailsContent {
  showHistoryFiltering: boolean;
  submissionId: number;
  documentId: string;
  contentType: DocumentType;
  contentTitle: string;
  originalFileName: string;
  secondaryOriginalFileName: string | undefined;
  url: string;
  productGroupName: string;
  version: number;
  documentReadonlySections?: DocumentDetailsReadonlySections;
  documentProcessingDetails: DocumentProcessingDetails;
  isFormExtended: boolean;
  documents: IDocumentDto[];
  isAdmin?: boolean;
  onCancel: () => void;
  onSuccess: () => void;
  onFileDownload?: () => void;
  onSecondaryFileDownload?: () => void;
}

const DocumentDetailsContent = ({
  showHistoryFiltering,
  submissionId,
  documentId,
  contentType,
  contentTitle,
  originalFileName,
  secondaryOriginalFileName,
  url,
  version,
  isFormExtended,
  documents,
  documentReadonlySections,
  documentProcessingDetails,
  productGroupName,
  isAdmin = false,
  onCancel,
  onSuccess,
  onFileDownload,
  onSecondaryFileDownload,
}: IDocumentDetailsContent) => {
  const { showVersionHistory } = useFeatureFlags();

  const document = documents.find((d) => d.documentId === Number(documentId) && d.type === contentType);

  const attributes = getDocumentAttributes(contentType, isAdmin);
  const documentWithAttributes = { document, attributes };

  const { alternativeTextFileName } = useGetAlternativeTextFile(
    submissionId.toString(),
    documentId,
    documentWithAttributes.attributes,
  );

  // for qrd Pils we would like to load details from epil as there may be different submission reasons on both of those types
  const documentTypeToLoad =
    contentType === DocumentType.Pil && documentProcessingDetails.documentProcessingFlow === DocumentProcessingFlow.qrdPil
      ? DocumentType.Epil
      : contentType;

  const { handleSubmit, methods } = usePrepareContentDetailsForm(
    documentTypeToLoad,
    documents,
    document?.documentId,
    documentProcessingDetails,
  );
  const [isValidationPopupOpen, setIsValidationPopupOpen] = useState(false);
  const [missingFields, setMissingFields] = useState<string[]>([]);
  const shouldAlternativeTextFileBeIncluded = methods.watch(ContentDetailsFormField.hasAlternativeTextFile);

  const fileAttributes = useGeneratePrimaryFileDescription(contentType, documentProcessingDetails.documentProcessingFlow);

  useEffect(() => {
    if (alternativeTextFileName !== undefined) {
      methods.setValue(ContentDetailsFormField.hasAlternativeTextFile, true);
    }
  }, [alternativeTextFileName, methods]);

  const excludedFieldsToValidate = [
    ContentDetailsFormField.details,
    ContentDetailsFormField.hasDigitalVersion,
    ContentDetailsFormField.documentTitle,
    ContentDetailsFormField.hasAlternativeTextFile,
    ContentDetailsFormField.alternativeTextFileName,
    ContentDetailsFormField.isCustomerApprovalRequired,
    ContentDetailsFormField.isVisibleToHCP,
    ContentDetailsFormField.replacementSecondaryFile,
    ContentDetailsFormField.replacementSecondaryFileName,
    ContentDetailsFormField.customerUrl,
  ];

  const noDatesDocumentTypes = [
    DocumentType.Rmm,
    DocumentType.Dhcp,
    DocumentType.UserManual,
    DocumentType.SafetyAlert,
    DocumentType.ProductInformation,
    DocumentType.AudioVideo,
    DocumentType.Audio,
    DocumentType.Video,
  ];

  const noAuthTypes = [DocumentType.AudioVideo, DocumentType.SafetyAlert, DocumentType.ProductInformation];

  const contentDescriptionTypes = [
    DocumentType.AudioVideo,
    DocumentType.Audio,
    DocumentType.Video,
    DocumentType.Rmm,
    DocumentType.Dhcp,
  ];

  const hasHtml = [ContentDetailsFormField.contentDescription];

  if (!shouldAlternativeTextFileBeIncluded) {
    excludedFieldsToValidate.push(ContentDetailsFormField.alternativeTextFile);
  }

  if (contentType !== DocumentType.Pil) {
    excludedFieldsToValidate.push(ContentDetailsFormField.isCustomerApprovalRequired);
  }

  if (noDatesDocumentTypes.includes(contentType)) {
    excludedFieldsToValidate.push(ContentDetailsFormField.authorisedDate);
    excludedFieldsToValidate.push(ContentDetailsFormField.regulatorApprovalDate);
  }

  if (!contentDescriptionTypes.includes(contentType)) {
    excludedFieldsToValidate.push(ContentDetailsFormField.contentDescription);
  }

  if (!(documentWithAttributes.attributes.allowAdminWeblinkUpload || ([DocumentType.Rmm].includes(contentType) && url))) {
    excludedFieldsToValidate.push(ContentDetailsFormField.webLink);
  }

  if (!documentWithAttributes.attributes.allowHtmlContentUpload) {
    excludedFieldsToValidate.push(ContentDetailsFormField.htmlContent);
  }

  if (noAuthTypes.includes(contentType)) {
    excludedFieldsToValidate.push(ContentDetailsFormField.authorisedDate);
    excludedFieldsToValidate.push(ContentDetailsFormField.regulatorApprovalDate);
    excludedFieldsToValidate.push(ContentDetailsFormField.approvedByRegulator);
    excludedFieldsToValidate.push(ContentDetailsFormField.isRegulatorApproved);
  }

  const { validateData, clearValidationFields } = useValidateData(excludedFieldsToValidate, hasHtml);

  const { updateDocument } = useUpdateDocument(submissionId, isFormExtended, contentType, documentWithAttributes.attributes);

  const handleSave = () => {
    handleSubmit((data) => {
      const validatedData = validateData(data);
      clearValidationFields(validatedData, !isFormExtended);

      if (validatedData.length) {
        setMissingFields(validatedData);
        return setIsValidationPopupOpen(true);
      }

      updateDocument(
        { documentId: parseInt(documentId!), documentType: contentType, ...data },
        {
          onSuccess: () => onSuccess(),
        },
      );
    })();
  };

  const handleValidatePopupCancel = () => {
    setIsValidationPopupOpen(false);
  };

  const getTabs = () => {
    const items = [
      {
        label: `${documentTypeToCustomLabelMap[contentType]} details`,
        content: (
          <DocumentContent
            documentTitle={contentTitle}
            documentReadonlySections={documentReadonlySections}
            version={version}
            fileName={originalFileName}
            url={url}
            customerUrl={documentProcessingDetails.customerUrl}
            htmlContent={documentWithAttributes.document?.htmlContent ?? ''}
            contentType={contentType}
            onFileOpen={onFileDownload}
            documentAttributes={documentWithAttributes!.attributes}
            documentProcessingFlow={documentProcessingDetails.documentProcessingFlow}
            secondaryFileName={secondaryOriginalFileName}
            onSecondaryFileOpen={onSecondaryFileDownload}
            fileDescriptions={fileAttributes}
          />
        ),
      },
    ];

    if (showVersionHistory) {
      items.push({
        label: `Version history`,
        content: (
          <DocumentVersionHistory
            documentId={documentWithAttributes.document?.documentId ?? 0}
            documentType={documentWithAttributes.document?.type}
            showHistoryFiltering={showHistoryFiltering}
          />
        ),
      });
    }

    return items;
  };

  const renderContent = () => {
    switch (contentType) {
      case DocumentType.Smpc:
      case DocumentType.Pil:
      case DocumentType.Rmm:
      case DocumentType.Dhcp:
      case DocumentType.UserManual:
      case DocumentType.SafetyAlert:
      case DocumentType.ProductInformation:
      case DocumentType.AudioVideo:
      case DocumentType.Audio:
      case DocumentType.Video:
        return <Tabs items={getTabs()} />;
      default:
        return <>Work in progress</>;
    }
  };

  return (
    <>
      <Styled.ContentDetailsWrap>
        <FormProvider {...methods}>
          <SubmissionFlowHeader title={productGroupName} onBackClick={onCancel} onCloseClick={onCancel}>
            <HeaderBreadcrumb>
              <Link>Product Family</Link> &gt; <Link>Product Group</Link> &gt; Document details
            </HeaderBreadcrumb>
          </SubmissionFlowHeader>
          {renderContent()}
          <ValidationError
            missingFields={missingFields}
            isOpen={isValidationPopupOpen}
            contentType={contentType!}
            handleClose={handleValidatePopupCancel}
            handleConfirm={handleValidatePopupCancel}
          />
        </FormProvider>
      </Styled.ContentDetailsWrap>
      <SubmissionFlowFooter onContinue={handleSave} continueText="Save changes" onCancel={onCancel} />
    </>
  );
};

export default DocumentDetailsContent;
