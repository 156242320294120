import React, { useState } from 'react';
import * as SharedStyled from '@common/styles';
import { Section } from '@common/components/Section/Section';
import { SectionsWrap, DateWrapper, ContentWrapper } from './styles';
import { Select } from '@common/components';
import { ISelectOption } from '@common/types';
import { HookFormDatePicker } from '@common/components/DatePickerWrapper/HookFormDatePicker';
import { AuditsForm, useAuditsForm } from './useAuditsForm';
import { FormProvider } from 'react-hook-form';
import { ContentReportFilters } from './ContentReportFilters';
import { ProductReportFilters } from './ProductReportFilters';
import SubmissionFlowFooter from '@common/components/SubmissionFlowFooter/SubmissionFlowFooter';
import { NotifyModal } from '@common/components/NotifyModal';
import { useGetProductReportData } from './hooks/useGetProductReportData';
import { useGetContentReportData } from './hooks/useGetContentReportData';
import { useDownloadAndOpenFile } from '@common/hooks/useDownloadAndOpenFile';
import { getIsoStringWithTimezoneHandling } from '@common/helpers';
import { useDownloadReportModal } from './hooks/useDownloadReportModal';
import { DownloadReportFileModalContent } from '../../components/DownloadReportFileModalContent';
import { ModalTypes } from '@common/components/NotifyModal/NotifyModal';

export enum AuditReportType {
  ContentAuditReport = 'ContentAuditReport',
  ProductAuditReport = 'ProductAuditReport',
}

const Audits = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedReport, setSelectedReport] = useState<ISelectOption>(options[0]);
  const { methods, reset, handleSubmit } = useAuditsForm(selectedReport.value as AuditReportType);

  const { getAnchorParams } = useDownloadAndOpenFile();
  const { downloadReportModalState, showDownloadReportModal, hideDownloadReportModal } = useDownloadReportModal();

  const reportErrorCallback = () => {
    setIsOpen(true);
  };

  const generateReportCallback = (response: any) => {
    const { url, cleanFileName } = getAnchorParams(response);

    showDownloadReportModal(url, cleanFileName);
  };

  const { generateProductReport } = useGetProductReportData({
    successCallback: generateReportCallback,
    errorCallback: reportErrorCallback,
  });

  const { generateContentReport } = useGetContentReportData({
    successCallback: generateReportCallback,
    errorCallback: reportErrorCallback,
  });

  const handleReportChange = (value: ISelectOption) => {
    setSelectedReport(value);
  };

  const handleCancel = () => {
    reset();
  };

  const handleConfirm = () => {
    setIsOpen(false);
  };

  const handleContinue = () => {
    handleSubmit((data) => {
      const defaultFilterSet = {
        from: data.fromDate ? getIsoStringWithTimezoneHandling(data.fromDate) : null,
        to: data.toDate ? getIsoStringWithTimezoneHandling(data.toDate) : null,
      };

      if (selectedReport.value === AuditReportType.ContentAuditReport) {
        const filters = {
          ...defaultFilterSet,
          documentTypes: data.contentTypes ?? [],
          includeAllVersionsContent: data.includeAllVersionsContent,
          includeRetired: data.includeRetiredContent,
          columns: data.contentColumns ?? [],
        };
        generateContentReport(filters);
      }

      if (selectedReport.value === AuditReportType.ProductAuditReport) {
        const filters = {
          ...defaultFilterSet,
          statuses: data.productStatuses ?? [],
          columns: data.productColumns ?? [],
        };
        generateProductReport(filters);
      }
    })();
  };

  return (
    <>
      <FormProvider {...methods}>
        <SharedStyled.SubmissionFlowWrap>
          <SharedStyled.SubmissionFlowInner>
            <SharedStyled.PageTitle>Download a Report</SharedStyled.PageTitle>
            <SharedStyled.PageSubTitle>
              Use this step by step process to manage your portfolio of products
            </SharedStyled.PageSubTitle>
            <SectionsWrap>
              <Section text="What report would you like to generate?">
                <Select name={''} value={selectedReport} options={options} onChange={handleReportChange} className="auditType" />
              </Section>
              <Section text="Enter date range">
                <DateWrapper>
                  <HookFormDatePicker name={AuditsForm.fromDate} label="Date from" maxDate={new Date()} />
                  <HookFormDatePicker name={AuditsForm.toDate} label="Date to" maxDate={new Date()} />
                </DateWrapper>
              </Section>
              {selectedReport?.value && (
                <Section text="Content report filters">
                  <ContentWrapper>{renderFilters(selectedReport?.value)}</ContentWrapper>
                </Section>
              )}
            </SectionsWrap>
          </SharedStyled.SubmissionFlowInner>
        </SharedStyled.SubmissionFlowWrap>
        <SubmissionFlowFooter onCancel={handleCancel} onContinue={handleContinue} />
      </FormProvider>
      <NotifyModal isOpen={isOpen} onConfirm={handleConfirm}>
        Raport could not be generated
      </NotifyModal>
      <NotifyModal
        modalType={ModalTypes.info}
        title="Your report file is ready"
        isOpen={downloadReportModalState.isOpen}
        onClose={hideDownloadReportModal}
        cancelText="Close"
      >
        <DownloadReportFileModalContent modalParams={downloadReportModalState} />
      </NotifyModal>
    </>
  );
};

const renderFilters = (reportType: string) => {
  switch (reportType) {
    case AuditReportType.ContentAuditReport:
      return <ContentReportFilters />;
    case AuditReportType.ProductAuditReport:
      return <ProductReportFilters />;
    default:
      return null;
  }
};

const options = [
  { label: 'Content report', value: AuditReportType.ContentAuditReport },
  { label: 'Product report', value: AuditReportType.ProductAuditReport },
];

export default Audits;
