import React from 'react';
import { TabContentWrapper } from '@common/features/productGroup/styles';
import DocumentsTabContent from './DocumentsTabContent';
import { CONTENT_TABS } from '../constants';
import Switcher, { ISwitcherOption } from '@common/components/Switcher/Switcher';
import { DocumentType } from '@common/types';
import ReadonlyDocumentsTabContent from './ReadonlyDocumentsTabContent';
import { IDocumentDto } from '@common/features/submission/types';
import { getDocumentTypeLabel } from '@common/helpers';
import { useFeatureFlags } from '@common/hooks/useFeatureFlags';
import { documentTypeToCustomLabelMap } from '@common/constants';
import { isMedia } from '@common/features/document/helpers';
import { AddModalOpenModes } from '@common/features/document/types';

const rmmDhpcUserManualTabs: ISwitcherOption[] = [
  { value: DocumentType.Rmm, label: documentTypeToCustomLabelMap.RMM },
  {
    value: DocumentType.Dhcp,
    label: documentTypeToCustomLabelMap.DHCP,
  },
  { value: DocumentType.UserManual, label: documentTypeToCustomLabelMap.UserManual },
];

const productSafetyAndAudioVideoTabs: ISwitcherOption[] = [
  { value: DocumentType.SafetyAlert, label: documentTypeToCustomLabelMap.SafetyInfo },
  { value: DocumentType.ProductInformation, label: documentTypeToCustomLabelMap.ProductInfo },
  { value: DocumentType.AudioVideo, label: documentTypeToCustomLabelMap.AudioVideo },
];

interface IContent {
  contentType: DocumentType;
  documents: IDocumentDto[];
  editable?: boolean;
  onContentRemove?: (documentId: string, title: string) => void;
  onContentRetire?: (documentId: string) => void;
  onContentAdd?: (productGroupName: string, documentId?: number, mode?: AddModalOpenModes) => void;
  onContentDetailsClick?: (documentId: string) => void;
  onContentTypeSelect: (contentType: DocumentType) => void;
  onRequestEpil?: (documentId: string) => void;
  showAudioVideoUpdateWarning?: () => void;
}

const Content = ({
  documents,
  contentType,
  editable = true,
  onContentRemove,
  onContentRetire,
  onContentAdd,
  onContentDetailsClick,
  onContentTypeSelect,
  onRequestEpil,
  showAudioVideoUpdateWarning
}: IContent) => {
  const { showRmmDhpcUserManualOnAssociateContent, showSafetyAlertProductInformationOnAssociateContent } = useFeatureFlags();

  const selectedContentType = [DocumentType.Audio, DocumentType.Video].includes(contentType)
    ? DocumentType.AudioVideo
    : contentType;

  const documentsByContentType = isMedia(contentType)
    ? documents.filter((t) => isMedia(t.type))
    : documents.filter((d) => d.type === contentType);

  const tabs = structuredClone(CONTENT_TABS);

  if (showRmmDhpcUserManualOnAssociateContent) {
    tabs.push(...rmmDhpcUserManualTabs);
  }

  if (showSafetyAlertProductInformationOnAssociateContent) {
    tabs.push(...productSafetyAndAudioVideoTabs);
  }

  return (
    <TabContentWrapper className="tabContentWrapper">
      <Switcher
        items={tabs}
        onOptionClick={onContentTypeSelect}
        selectedOption={{ value: selectedContentType, label: getDocumentTypeLabel(selectedContentType) }}
      />
      {editable ? (
        <DocumentsTabContent
          documents={documentsByContentType}
          contentType={selectedContentType}
          onContentAdd={onContentAdd!}
          onContentTypeSelect={onContentTypeSelect}
          onContentRemove={onContentRemove!}
          onContentRetire={onContentRetire!}
          onContentDetailsClick={onContentDetailsClick!}
          onRequestEpil={onRequestEpil!}
          showAudioVideoUpdateWarning={showAudioVideoUpdateWarning}
        />
      ) : (
        <ReadonlyDocumentsTabContent
          documents={documentsByContentType}
          contentType={selectedContentType}
          onContentDetailsClick={onContentDetailsClick!}
        />
      )}
    </TabContentWrapper>
  );
};

export default Content;
