import React, { useState } from 'react';

import * as Styled from '../../styles';
import { useFetchActiveIngredients } from '@common/hooks/useFetchActiveIngredients';
import { useActiveIngredientSelection } from '@common/components/ActiveIngredientsSelection/hooks/useActiveIngredientSelection';
import { ActiveIngredientsSelection } from '@common/components/ActiveIngredientsSelection/ActiveIngredientsSelection';
import { IActiveIngredientOption } from '@common/features/product/types';

interface IActiveIngredientsContent {
  activeIngredientsFieldName: string;
  noActiveIngredientsCheckboxFieldName: string;
  onActiveIngredientAdd?: (activeIngredient: IActiveIngredientOption | '') => void;
  onActiveIngredientRemove?: (index: number) => void;
  onNoActiveIngredientsClick?: () => void;
}

export const ActiveIngredientsContent = ({
  activeIngredientsFieldName,
  noActiveIngredientsCheckboxFieldName,
  onActiveIngredientAdd,
  onActiveIngredientRemove,
  onNoActiveIngredientsClick,
}: IActiveIngredientsContent) => {
  const [searchTerm, setSearchTerm] = useState('');
  const { activeIngredients, isFetchingActiveIngredients } = useFetchActiveIngredients(searchTerm);

  const {
    handleActiveIngredientAdd,
    handleActiveIngredientInputChange,
    handleActiveIngredientRemove,
    handleDraftActiveIngredientChange,
    handleNoActiveIngredientsChange,
    activeIngredientToAdd,
    watch,
    errors,
  } = useActiveIngredientSelection({
    activeIngredientsFieldName: activeIngredientsFieldName,
    noActiveIngredientsCheckboxFieldName: noActiveIngredientsCheckboxFieldName,
  });

  const selectedActiveIngredients = watch(activeIngredientsFieldName);
  const isNoActiveIngredientsChecked = watch(noActiveIngredientsCheckboxFieldName);
  const noActiveIngredientsError = errors[activeIngredientsFieldName]?.message?.toString();
  const alreadyExistsError = errors[noActiveIngredientsCheckboxFieldName]?.message?.toString();

  const handleSubstanceAdd = () => {
    const canAdd = handleActiveIngredientAdd();
    if (canAdd) {
      onActiveIngredientAdd && onActiveIngredientAdd(activeIngredientToAdd);
    }
  };

  const handleSubstanceRemove = (index: number) => {
    onActiveIngredientRemove ? onActiveIngredientRemove(index) : handleActiveIngredientRemove(index);
  };

  const handleNoActiveIngredientsClick = () => {
    onNoActiveIngredientsClick && onNoActiveIngredientsClick();
    handleNoActiveIngredientsChange();
  };

  return (
    <Styled.TabContentWrapper>
      <Styled.BadgeElementWrapper>
        <Styled.Subtitle data-testid="active-ingredients-tab-title">Active Ingredients</Styled.Subtitle>
      </Styled.BadgeElementWrapper>
      <Styled.Content>
        <Styled.Description>
          Changes to the active ingredient(s) in this section will apply to all products within the product group
        </Styled.Description>

        <Styled.ProductGroupTabForm>
          <ActiveIngredientsSelection
            noTitle={true}
            activeIngredients={activeIngredients}
            isFetchingActiveIngredients={isFetchingActiveIngredients}
            activeIngredientToAdd={activeIngredientToAdd}
            selectedActiveIngredients={selectedActiveIngredients}
            isNoActiveIngredientsChecked={isNoActiveIngredientsChecked}
            noActiveIngredientsError={noActiveIngredientsError}
            alreadyExistsError={alreadyExistsError}
            onActiveIngredientAdd={handleSubstanceAdd}
            onActiveIngredientRemove={handleSubstanceRemove}
            onActiveIngredientInputChange={(value, action) => {
              setSearchTerm(value);
              handleActiveIngredientInputChange(value, action);
            }}
            onNoActiveIngredientsChange={handleNoActiveIngredientsClick}
            onDraftActiveIngredientChange={handleDraftActiveIngredientChange}
          />
        </Styled.ProductGroupTabForm>
      </Styled.Content>
    </Styled.TabContentWrapper>
  );
};
