import { RootState } from '@common/store';
import { getData } from '@services/requestService';
import { createAsyncThunkWithCustomErrorHandling } from '../helpers';

export const fetchUserData = createAsyncThunkWithCustomErrorHandling('user/user', async (_, { getState }) => {
  const state = getState() as RootState;
  const token = state.user.token;
  const data = await getData(token, '/user', '');

  return data;
});

export const fetchIdentityUserData = createAsyncThunkWithCustomErrorHandling('user/userInfo', async (_, { getState }) => {
  const state = getState() as RootState;
  const token = state.user.token;
  const data = await getData(token, '/userInfo', '', `${process.env.REACT_APP_AUTH_URL}/connect`, false);

  return data;
});
