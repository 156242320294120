import { AsyncThunkPayloadCreator, createAsyncThunk } from '@reduxjs/toolkit';
import { HttpStatusCode } from 'axios';
import { setHasUserInsufficientPermissions } from './user/userSlice';

export const createAsyncThunkWithCustomErrorHandling = <Returned, ThunkArg>(
  type: string,
  payloadCreator: AsyncThunkPayloadCreator<Returned, ThunkArg>,
) => {
  return createAsyncThunk(type, async (arg: ThunkArg, thunkAPI) => {
    try {
      return await payloadCreator(arg, thunkAPI);
    } catch (error: any) {
      if (error.cause === HttpStatusCode.Forbidden) {
        thunkAPI.dispatch(setHasUserInsufficientPermissions(true));
      }

      return thunkAPI.rejectWithValue({ cause: error.cause });
    }
  });
};
