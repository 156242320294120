import { Sla } from './SlaSection/types';
import { Tutorial } from './types';
import { News } from './NewsSection/types';

const newsItems: Array<News> = [
  {
    date: `27-Nov-2024`,
    title: `Important update on emc Northern Ireland`,
    link: `https://support.datapharm.com/knowledge/windsor-framework/`,
    decription: `Having consulted with the MHRA, Datapharm's plans are now available for the emc ni site in anticipation of the Windsor Framework implementation on 1 January 2025, which reintegrates Northern Ireland into a UK-wide regulatory environment.`,
  },
  {
    date: `06-Nov-2024`,
    title: `What information do HCPs need from Pharma’s Medical Information teams?`,
    link: `https://www.datapharm.com/resource-hub/webinar-what-information-do-hcps-need-from-pharma-s-medical-information-teams/`,
    decription: `In this webinar, hosted by Datapharm and MILE (Medical Information Leaders in Europe), Medical Information peers will learn how HCPs are searching SRDs (Scientific Response Documents) online, including search trends and best practice tips.`,
  },
  {
    date: `18-Oct-2024 `,
    title: `Datapharm Customer Meeting 2024 roundup: Embrace the grey`,
    link: `https://www.datapharm.com/resource-hub/datapharm-customer-meeting-2024-roundup/`,
    decription: `Datapharm welcomed its customers in London for its annual customer event, bringing together Pharma professionals in Regulatory Affairs, Medical Information and Commercial for a day packed with industry insights, expert sharings and networking.`,
  },
];

const oldSlaItems: Array<Sla> = [
  { name: 'Summary of Product Characteristics (SmPC):', value: '5 working days' },
  { name: 'Patient Information Leaflets (PILs):', value: '1 working day' },
  { name: 'Electronic Patient Information Leaflets (ePILs)', value: '10 working days' },
  { name: 'User Manuals:', value: '2 working days' },
  { name: 'Risk Minimisation Materials:', value: '5 working days' },
  { name: 'Safety Alerts:', value: '5 working days' },
  { name: 'Product Information:', value: '5 working days' },
  { name: 'Direct Healthcare Professional Communications (DHPC letters):', value: '5 working days' },
  { name: 'Videos, audios and live chat:', value: 'on a case-by-case basis' },
];

const newSlaItems: Array<Sla> = [
  {
    name: 'Summary of Product Characteristics (SmPC):',
    value: '5 working days',
    deadlines: ['13/12/2024', '16/12/2024'],
  },
  {
    name: 'Patient Information Leaflets (PILs):',
    value: '1 working day',
    deadlines: ['19/12/2024', '27/12/2024'],
  },
  {
    name: 'Electronic Patient Information Leaflets (ePILs)',
    value: '10 working days',
    deadlines: ['05/12/2024', '11/12/2024'],
  },
  { name: 'User Manuals:', value: '2 working days', deadlines: ['18/12/2024', '23/12/2024'] },
  {
    name: 'Risk Minimisation Materials:',
    value: '5 working days',
    deadlines: ['13/12/2024', '16/12/2024'],
  },
  { name: 'Safety Alerts:', value: '5 working days', deadlines: ['13/12/2024', '16/12/2024'] },
  { name: 'Product Information:', value: '5 working days', deadlines: ['13/12/2024', '16/12/2024'] },
  {
    name: 'Direct Healthcare Professional Communications (DHPC letters):',
    value: '5 working days',
    deadlines: ['13/12/2024', '16/12/2024'],
  },
  {
    name: 'Videos, audios and live chat:',
    value: '5 working days',
    deadlines: ['13/12/2024', '16/12/2024'],
  },
];

const tutorialItems: Array<Tutorial> = [
  { name: 'How do I add or update a Product?', link: process.env.REACT_APP_HELP_PRODUCT_URL },
  { name: 'How do I add or update content?', link: process.env.REACT_APP_HELP_CONTENT_URL },
  { name: 'How do I add or update a Product group?', link: process.env.REACT_APP_HELP_PRODUCT_GROUP_URL },
  { name: 'How do I associate content to a Product?', link: process.env.REACT_APP_HELP_ASSOCIATE_CONTENT_URL },
];

export { newsItems, newSlaItems, oldSlaItems, tutorialItems };
