import React from 'react';
import { NotifyModal } from '@common/components/NotifyModal';
import { ModalTypes } from '@common/components/NotifyModal/NotifyModal';
import * as Styled from '@common/styles';

interface IUnableToUpdateAudioVideoModal {
  isOpen: boolean;
  onConfirm: () => void;
}

export const UnableToUpdateAudioVideoModal = ({ isOpen, onConfirm }: IUnableToUpdateAudioVideoModal) => {
  return (
    <NotifyModal
      title="Unable to update"
      width="570px"
      modalType={ModalTypes.error}
      isOpen={isOpen}
      cancelText="Confirm"
      onConfirm={onConfirm}
    >
      <Styled.ModalContentSmall>
        {`Unfortunately we are unable to update audio or video content that has been migrated from our old system;
        on this ocasion please submit this content as New and retire the version that is no longer needed.`}
        <br/>
        <br />
        {`Going forward you will be able to update the content versions as you need.`}
        <br/>
        <br />
        {`We apologise for any inconvenience`}
      </Styled.ModalContentSmall>
    </NotifyModal>
  );
};
