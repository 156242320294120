import React from 'react';
import { useLocation, useParams } from 'react-router';
import useAppNavigation from '../../routing/useAppNavigation';
import PublishedDocumentDetailsContent from '@common/features/document/pages/ContentDetails/PublishedDocumentDetailsContent';
import { DocumentType } from '@common/types';
import { useDownloadAndOpenPublishedFile } from '@hooks/useDownloadAndOpenPublishedFile';
import { useFetchPublicProductGroupState } from '@features/productGroup/hooks/useFetchPublicProductGroupState';
import { useGetOriginalFileExtensionType } from '@hooks/useGetOriginalFileExtensionType';
import { hasOriginalFilePublished } from '@features/document/helpers';

interface IReadonlyContentDetails {
  contentType: DocumentType;
}

const PublishedContentDetails = ({ contentType }: IReadonlyContentDetails) => {
  const location = useLocation();
  const state = location.state;
  const { productGroupId, documentId } = useParams();
  const { goToPublishedProductGroup } = useAppNavigation();
  const { getExtensionByDocumentType } = useGetOriginalFileExtensionType();

  const { publicProductGroupState } = useFetchPublicProductGroupState(productGroupId!);

  const document = publicProductGroupState?.documents.find((d) => d.documentId === Number.parseInt(documentId!));
  const version = document?.version ?? 1;

  const { downloadAndOpen } = useDownloadAndOpenPublishedFile();
  const handleFileDownload = () =>
    downloadAndOpen({
      documentId,
      version: version.toString(),
      documentType: document?.type,
      extensionType: getExtensionByDocumentType(document?.type),
    });

  const handleCancel = () => {
    goToPublishedProductGroup(productGroupId!, state);
  };

  const documentHasOriginalFilePublished = hasOriginalFilePublished(document);

  return (
    <PublishedDocumentDetailsContent
      showHistoryFiltering={false}
      contentType={contentType}
      productGroupId={productGroupId!}
      documentId={documentId!}
      onCancel={handleCancel}
      onFileDownload={documentHasOriginalFilePublished ? handleFileDownload : undefined}
    />
  );
};

export default PublishedContentDetails;
