import React from 'react';
import { useParams } from 'react-router';
import { useFetchPublicProductGroupState } from '@common/features/productGroup/hooks/useFetchPublicProductGroupState';
import { useCreateDraftFromProductGroup } from '../CreateSubmission/hooks/useCreateDraftFromProductGroup';
import useAppNavigation, { BackToContentTypeState } from '../../routing/useAppNavigation';
import { DocumentType } from '@common/types';
import { ReadonlyProductGroup } from '../../features/productGroup/components/ReadonlyProductGroup';
import { SubmissionStatus } from '@common/services/types';

const PublishedProductGroup = () => {
  const { productGroupId = '' } = useParams();
  const { goToProductGroup, goToHome, goToPublishedProduct, goToPublishedDocumentContentDetails } = useAppNavigation();

  const { mutate } = useCreateDraftFromProductGroup((submissionId) => goToProductGroup(submissionId.toString()));
  const { publicProductGroupState } = useFetchPublicProductGroupState(productGroupId);

  const handleProductDetailsClick = (productId: string) => goToPublishedProduct(productGroupId, productId);
  const handleDocumentDetailsClick = (documentId: string, contentType: DocumentType) => {
    const backToContentState: BackToContentTypeState = { contentType };

    goToPublishedDocumentContentDetails(productGroupId, contentType, documentId, backToContentState);
  }

  const tableCallbacks = {
    onDetailsClick: handleProductDetailsClick,
  };

  const publishedProductGroupTitle =
    publicProductGroupState?.productGroupId && publicProductGroupState?.productGroup.productGroupName
      ? `${publicProductGroupState?.productGroupId}_${publicProductGroupState?.productGroup.productGroupName}`
      : '';

  const createNewSubmission = () => {
    publicProductGroupState &&
      mutate({
        familyId: publicProductGroupState.productGroup.productFamilyId.toString(),
        familyName: publicProductGroupState.productGroup.productFamilyName,
        id: publicProductGroupState.productGroupId.toString(),
        name: publicProductGroupState.productGroup.productGroupName,
        type: publicProductGroupState.productGroup.productType,
      });
  };

  const handleCancel = () => goToHome();

  return (
    <ReadonlyProductGroup
      submissionStatus={SubmissionStatus.Published}
      submissionTitle={publishedProductGroupTitle}
      dataSource={publicProductGroupState!}
      productTableCallbacks={tableCallbacks}
      onCancel={handleCancel}
      onContinue={createNewSubmission}
      continueBtnText="Create Submission"
      onDocumentDetailsClick={handleDocumentDetailsClick}
    />
  );
};

export default PublishedProductGroup;
