import { useState } from 'react';
import { useParams } from 'react-router';

import useAppNavigation from 'apps/publisher-v3.ui.customer/src/routing/useAppNavigation';

import { IDocumentDto } from '@common/features/submission/types';
import { ProductTabs } from '@common/features/product/components/ProductContent/types';
import { ErrorLog } from '@common/types';

export const useValidateSubmissionModal = (
  documents: IDocumentDto[],
  onProductGroupErrorClick?: (errorLog: ErrorLog) => void,
) => {
  const [isValidateOpen, setIsValidateOpen] = useState(false);
  const { goToProduct, goToDocumentContentDetails } = useAppNavigation();
  const { submissionId } = useParams();

  const handleCancelValidationModal = () => setIsValidateOpen(false);
  const handleProductGroupErrorClick = (errorLog: ErrorLog) => {
    onProductGroupErrorClick && onProductGroupErrorClick(errorLog);
    setIsValidateOpen(false);
  };

  const handleProductErrorClick = (id: number, state?: any) => goToProduct(submissionId!, id.toString(), state);
  const handleContentErrorClick = (id: number, state?: any) => {
    const foundContentType = documents.find((d) => d.documentId === id)?.type;
    goToDocumentContentDetails(submissionId!, foundContentType!, id.toString(), state);
  };
  const handleProductAssociationErrorClick = (id: number) =>
    goToProduct(submissionId!, id.toString(), { tab: ProductTabs.AssociatedContent });
  const handleContentAssociationErrorClick = () => setIsValidateOpen(false);

  return {
    isValidateOpen,
    setIsValidateOpen,
    handleCancelValidationModal,
    handleProductGroupErrorClick,
    handleProductErrorClick,
    handleContentErrorClick,
    handleProductAssociationErrorClick,
    handleContentAssociationErrorClick,
  };
};
