import React from 'react';
import { useFetchSubmission } from '@common/features/submission/hooks/useFetchSubmission';
import SubmissionProductContent from '@common/features/product/components/ProductContent/SubmissionProductContent';
import { useParams } from 'react-router';
import useAppNavigation from '../../routing/useAppNavigation';
import { CustomerAllowedTaskActions } from '@common/types';
import {
  AssociatedContentReadonlySection,
  ProductInfoReadonlySection,
} from '@common/features/product/components/ProductContent/types';
import { ReadonlyProductContent } from '@common/features/product/components/ProductContent/ReadOnlyProductContent';
import { useFetchSubmissionTaskData } from '@common/hooks/useFetchSubmissionTaskData';
import ProductContentSkeletonLoader from '@common/features/product/components/ProductContent/ProductContentSkeletonLoader';
import { getSingleProduct, isProductRetired } from '@common/features/product/components/ProductContent/helpers';

interface ISubmissionTaskProduct {
  readonly?: boolean;
}

export const SubmissionTaskProduct = ({ readonly = false }: ISubmissionTaskProduct) => {
  const { goToSubmissionTaskDetails } = useAppNavigation();
  const { submissionId = '', productId = '', taskId = '' } = useParams();
  const { submissionTask, isFetchingSubmissionTaskData } = useFetchSubmissionTaskData(taskId);
  const { submission, isFetchingSubmission } = useFetchSubmission(submissionId);

  const redirectToSubmissionTaskDetails = () => {
    goToSubmissionTaskDetails(submissionId, taskId);
  };

  const isEditable =
    submissionTask?.allowedCustomerActions.includes(CustomerAllowedTaskActions.CustomerCanEditTaskDetails) && !readonly;

  const associatedContentReadonlySections = {
    [AssociatedContentReadonlySection.editDocumentName]: true,
    [AssociatedContentReadonlySection.pilSmpcSelection]: true,
    [AssociatedContentReadonlySection.secondaryDocumentsSelection]: true,
  };

  const product = getSingleProduct(productId, submission?.products!);
  const isRetired = isProductRetired(product!);

  const isMissingProductNameDetails = !product?.hasPublishedIngredientStrenghts;

  const productInfoReadonlySections = {
    [ProductInfoReadonlySection.productForm]: !isMissingProductNameDetails,
    [ProductInfoReadonlySection.activeIngredients]: !isMissingProductNameDetails,
    [ProductInfoReadonlySection.extraInformation]: !isMissingProductNameDetails,
    [ProductInfoReadonlySection.regulatorApprovedName]: !isMissingProductNameDetails,
    [ProductInfoReadonlySection.genericName]: !isMissingProductNameDetails,
    [ProductInfoReadonlySection.displayName]: !isMissingProductNameDetails,
  };

  if (isFetchingSubmission || isFetchingSubmissionTaskData) {
    return <ProductContentSkeletonLoader />;
  }

  if (isEditable) {
    return (
      <SubmissionProductContent
        product={product}
        submission={submission!}
        onCancel={redirectToSubmissionTaskDetails}
        onResultSuccess={redirectToSubmissionTaskDetails}
        associatedContentReadonlySections={associatedContentReadonlySections}
        shouldDisplayAmpIds={false}
        shouldDisplayContactGroup={false}
        productInfoReadonlySections={productInfoReadonlySections}
        isProductRetired={isRetired}
      />
    );
  }

  return (
    <ReadonlyProductContent
      dataSource={submission!}
      productId={productId}
      onCancel={redirectToSubmissionTaskDetails}
      shouldDisplayAmpIds={false}
      shouldDisplayContactGroup={false}
    />
  );
};
