import { FileRoles } from '@common/features/document/types';
import { IProductData } from '@common/features/productGroup/types';
import { ISubstanceDto } from '@common/features/submission/types';

export enum RequestType {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
  PATCH = 'PATCH',
}

export enum SubmissionTaskStatuses {
  None = 'None',
  Draft = 'Draft',
  WithProduction = 'WithProduction',
  WithQC = 'WithQC',
  WithDataQuality = 'WithDataQuality',
  WithClientMoreInformation = 'WithClientMoreInformation',
  WithClientApproval = 'WithClientApproval',
  Approved = 'Approved',
  Published = 'Published',
  Cancelled = 'Cancelled',
}

export enum SubmissionTaskStatusesEnum {
  None = 0,
  Draft = 1,
  WithProduction = 2,
  WithQC = 4,
  WithDataQuality = 8,
  WithClientMoreInformation = 16,
  WithClientApproval = 32,
  Approved = 64,
  Cancelled = 128,
  Published = 256,
}

export enum SubmissionTaskStatusesCustomerEnum {
  None = 0,
  NeedsAttention = SubmissionTaskStatusesEnum.WithClientMoreInformation,
  WithDatapharm = SubmissionTaskStatusesEnum.WithProduction |
    SubmissionTaskStatusesEnum.WithQC |
    SubmissionTaskStatusesEnum.WithDataQuality,
  NeedsApproval = SubmissionTaskStatusesEnum.WithClientApproval,
  Approved = 64,
  Cancelled = 128,
  Published = 256,
}

export enum SubmissionStatus {
  None = 'None',
  Draft = 'Draft',
  InProgress = 'InProgress',
  Approved = 'Approved',
  Cancelled = 'Cancelled',
  Published = 'Published',
  Completed = 'Completed',
  WithDatapharm = 'WithDatapharm',
  All = 'All',
}

export enum UserTypes {
  Admin = 'Admin',
  Customer = 'Customer',
  All = 'All',
}

export interface ISubmissionTaskXmlDataResult {
  xml: string;
  errorsXml: string;
  images: string[];
  alternativeTextFileName: FileRoles;
}

export interface IHtmlPreviewCommand {
  xml: string;
}

export interface HtmlPreviewDto {
  operationSucceed: boolean;
  conversionErrorsXmlFile: string;
  message: string;
  html: string;
}

export interface ProductGroupSubstancesUpdateCommand {
  substances: ISubstanceDto[];
  productsData: IProductData[];
}

export interface IProductSubmissionStatusDto {
  submissionId: number;
  status: SubmissionStatus;
  companyId: number;
  title: string;
}