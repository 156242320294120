import { FormCheckboxesSection, RichTextEditor, Select } from '@common/components';
import { StyledLink } from '@common/components/Link/styles';
import { Section } from '@common/components/Section/Section';
import { SideDecorationContent } from '@common/components/SideDecorationContent/SideDecorationContent';
import { UploadedFile } from '@common/components/UploadedFile/UploadedFile';
import { documentTypeToCustomLabelMap } from '@common/constants';
import { DocumentState, ISubmissionReasonDto } from '@common/features/submission/types';
import { isStringNullOrEmpty, shouldRenderAlternativeTextFileSection } from '@common/helpers';
import * as SharedStyled from '@common/styles';
import { DocumentType, ISelectOption } from '@common/types';
import { epilConversionCheckboxTexts, pilAutoApproveCheckboxTexts } from '../../constants';
import { getDocumentAttributes } from '../../helpers';
import { ContentDetailsFormField } from '../../pages/ContentDetails/types';
import { ReadonlyAdditionalTextFileSection } from '../AdditionalTextFileSection/ReadonlyAdditionalTextFileSection';
import { ReadonlyContentDetailsWeblink } from '../ContentDetailsWeblink/ReadonlyContentDetailsWeblink';
import { DocumentTitleDisplay } from '../DocumentTitle/DocumentTitleDisplay';
import { ReadOnlyHealthCareProfessional } from '../HealthCareProfessional/ReadOnlyHealthCareProfessional';
import { ReadonlyRegulatorApproval } from '../RegulatorApproval/RegulatorApproval';
import { ReadonlySubmissionDetails } from '../SubmissionDetails/ReadonlySubmissionDetails';
import { ReadOnlySubmissionReason } from '../SubmissionReason/ReadonlySubmissionReason';
import { useGeneratePrimaryFileDescription } from './hooks/useGenerateFileDescription';
import * as Styled from './styles';
import { DocumentProcessingDetails, DocumentProcessingFlow } from './types';

export interface IReadonlyDocumentContent {
  documentTitle: string;
  version: number;
  fileName?: string;
  secondaryFileName: string | undefined;
  url: string | null;
  contentType: DocumentType;
  approvalDate: Date | null;
  approved: boolean;
  firstAuthorisationDate: Date | null;
  regulator: string;
  reasonsForSubmission: ISubmissionReasonDto[];
  reasonForRetirement?: ISelectOption;
  documentState: DocumentState;
  hasDigitalVersion: boolean | null;
  contentDescription: string | null;
  isVisibleToHCP: boolean | null;
  isAdmin: boolean;
  htmlContent: string | null;
  alternativeTextFileName?: string;
  documentProcessingDetails: DocumentProcessingDetails;
  onFileClick?: () => void;
  onSecondaryFileClick?: () => void;
  onAlternativeTextFileNameClick?: () => void;
}

export const ReadonlyDocumentContent = ({
  documentTitle,
  version,
  fileName,
  url,
  contentType,
  approvalDate,
  approved,
  firstAuthorisationDate,
  regulator,
  reasonsForSubmission,
  reasonForRetirement,
  documentState,
  hasDigitalVersion,
  contentDescription,
  isVisibleToHCP,
  alternativeTextFileName,
  htmlContent,
  isAdmin,
  secondaryFileName,
  documentProcessingDetails,
  onFileClick,
  onSecondaryFileClick,
  onAlternativeTextFileNameClick,
}: IReadonlyDocumentContent) => {
  const shouldRenderAltTextFileSection =
    shouldRenderAlternativeTextFileSection(contentType, hasDigitalVersion ?? false) &&
    alternativeTextFileName &&
    onAlternativeTextFileNameClick;
  const hasUrl = !isStringNullOrEmpty(url) || !isStringNullOrEmpty(documentProcessingDetails.customerUrl);

  const documentAttributes = getDocumentAttributes(contentType, isAdmin);
  const shouldRenderHtmlContent = htmlContent?.length! > 0;
  const htmlContentToolbarOptions = [
    [{ header: [1, 2, 3, 4, false] }],
    ['bold', 'italic', 'underline'],
    [{ list: 'ordered' }],
    ['link'],
  ];

  const { primaryFileDescription, secondaryFileDescription, primaryFileHeader, secondaryFileHeader } =
    useGeneratePrimaryFileDescription(contentType, documentProcessingDetails.documentProcessingFlow);

  return (
    <Styled.SmpcDetailsWrap>
      <SharedStyled.SubmissionFlowWrap>
        <SharedStyled.SubmissionFlowInner>
          <Styled.SmpcDetailsHeadingWrap>
            <Styled.SmpcDetailsHeadingText>{documentTypeToCustomLabelMap[contentType]} details</Styled.SmpcDetailsHeadingText>
          </Styled.SmpcDetailsHeadingWrap>
          <SideDecorationContent>
            {hasUrl || documentAttributes.allowFileUpload || documentAttributes.allowHtmlContentUpload ? (
              <Section text={`Your ${primaryFileDescription}`}>
                <>
                  {primaryFileHeader ? <p>{primaryFileHeader}</p> : null}
                  {!hasUrl && documentAttributes.allowFileUpload ? (
                    <UploadedFile
                      active={[
                        DocumentType.Pil,
                        DocumentType.Smpc,
                        DocumentType.Dhcp,
                        DocumentType.Rmm,
                        DocumentType.UserManual,
                      ].includes(contentType)}
                      fileName={fileName!}
                      onClick={onFileClick}
                    />
                  ) : null}
                </>

                {hasUrl && <ReadonlyContentDetailsWeblink customerUrl={documentProcessingDetails.customerUrl} url={url} />}

                {shouldRenderHtmlContent && documentAttributes.allowHtmlContentUpload ? (
                  <RichTextEditor
                    readOnly={true}
                    className="addModalEditorWrapper"
                    toolbarOptions={htmlContentToolbarOptions}
                    fieldName={''}
                    value={htmlContent}
                  />
                ) : null}
              </Section>
            ) : null}
            {documentProcessingDetails.documentProcessingFlow === DocumentProcessingFlow.qrdPil ? (
              <Section data-testid="title" text={`Your ${secondaryFileDescription}`}>
                <>
                  <p>{secondaryFileHeader}</p>
                  {!hasUrl ? (
                    <UploadedFile
                      active={[DocumentType.Pil].includes(contentType)}
                      fileName={secondaryFileName ?? ''}
                      onClick={onSecondaryFileClick}
                    />
                  ) : null}
                </>
              </Section>
            ) : null}
            <Section text="Document title" subtext="" linkAtEnd={<StyledLink to={'help'}>Learn more &gt;</StyledLink>}>
              <DocumentTitleDisplay title={documentTitle} />
            </Section>
            {contentType === DocumentType.Pil && documentProcessingDetails.documentProcessingFlow === DocumentProcessingFlow.standard ? (
              <SharedStyled.GroupedCheckboxSections>
                {(documentProcessingDetails.isCustomerApprovalRequired !== undefined && documentProcessingDetails.isCustomerApprovalRequired !== null) && (
                  <FormCheckboxesSection
                    data-testId="isCustomerApprovalRequired"
                    value={documentProcessingDetails.isCustomerApprovalRequired}
                    text={pilAutoApproveCheckboxTexts.title}
                    disabled
                    withBorderAndSubtext
                  />
                )}
                <FormCheckboxesSection
                  value={hasDigitalVersion ?? undefined}
                  data-testId="hasDigitalVersion"
                  field={ContentDetailsFormField.hasDigitalVersion}
                  text={epilConversionCheckboxTexts.title}
                  subtextSection={epilConversionCheckboxTexts.subtitle}
                  disabled
                  withBorderAndSubtext
                />
                {shouldRenderAltTextFileSection && (
                  <ReadonlyAdditionalTextFileSection
                    withBorderAndSubtext
                    alternativeTextFileName={alternativeTextFileName}
                    onAlternativeTextFileDownload={onAlternativeTextFileNameClick}
                  />
                )}
              </SharedStyled.GroupedCheckboxSections>
            ) : null}
            {documentAttributes && documentAttributes.showApproval ? (
              <Section text="Regulator approval" subtext="" linkAtEnd={<StyledLink to={'help'}>Learn more &gt;</StyledLink>}>
                <ReadonlyRegulatorApproval
                  approvalDate={approvalDate}
                  approved={approved}
                  contentType={contentType}
                  firstAuthorisationDate={firstAuthorisationDate}
                  regulator={regulator}
                />
              </Section>
            ) : null}
            <>
              {documentState === DocumentState.Retired && (
                <Section text="Retirement reason" subtext="" linkAtEnd={<StyledLink to={'help'}>Learn more &gt;</StyledLink>}>
                  <Select
                    name="retirement-reason"
                    value={reasonForRetirement!}
                    options={[]}
                    disabled={true}
                    onChange={() => {}}
                  />
                </Section>
              )}
            </>

            {documentAttributes && documentAttributes.showHcpOnly ? (
              <Section text="Audience Visibility">
                <ReadOnlyHealthCareProfessional isVisibleToHCP={isVisibleToHCP} />{' '}
              </Section>
            ) : null}

            {documentAttributes && documentAttributes.showContentDescription ? (
              <Section text="Content Description">
                <RichTextEditor readOnly={true} value={contentDescription} />
              </Section>
            ) : null}

            {documentAttributes && documentAttributes.showFreeTextReason ? (
              <Section text="Reasons For Submission">
                <ReadOnlySubmissionReason reasonsForSubmission={reasonsForSubmission} />
              </Section>
            ) : null}

            {documentAttributes && !documentAttributes.showFreeTextReason ? (
              <Section text="Submission details" subtext="" linkAtEnd={<StyledLink to={'help'}>Learn more &gt;</StyledLink>}>
                <ReadonlySubmissionDetails
                  contentType={contentType}
                  version={version}
                  details=""
                  reasonsForSubmission={reasonsForSubmission}
                />
              </Section>
            ) : null}
          </SideDecorationContent>
        </SharedStyled.SubmissionFlowInner>
      </SharedStyled.SubmissionFlowWrap>
    </Styled.SmpcDetailsWrap>
  );
};
