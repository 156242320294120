import { buildUrl, handleRequest } from '@common/services/requestHelper';
import { RequestType } from '@common/services/types';
import { Id, SubmissionStatus } from '@common/types';

const basePath = process.env.REACT_APP_API_PATH;

const buildQuery = (search: string | null, limit?: number) => {
  const query = [];

  search && query.push(`search=${search}`);
  limit && query.push(`limit=${limit}`);

  return query.join('&');
};

const fetchActiveIngredientsSearch = async (token: string, search: string) => {
  const url = buildUrl('/substances', basePath, `search=${search ?? ''}`);

  return handleRequest({ token, url, requestType: RequestType.GET });
};

const fetchUnitsOfMeasurement = async (token: string, search: string | null, limit?: number) => {
  let url = buildUrl(`/substances/unit`, basePath, buildQuery(search, limit));

  return handleRequest({ token, url, requestType: RequestType.GET });
};

const fetchMaNumbersConfig = async (token: string) => {
  const url = buildUrl(`/regulated-market`, basePath);

  return handleRequest({ token, url, requestType: RequestType.GET });
};

const fetchProductsTree = async (token: string, queryString: string) => {
  const url = buildUrl(`/product/tree`, basePath, queryString);

  return handleRequest({ token, url, requestType: RequestType.GET });
};

const fetchCompanyProducts = async (token: string, companyId: number, queryString: string) => {
  const url = buildUrl(`/product/company/${companyId}`, basePath, queryString);

  return handleRequest({ token, url, requestType: RequestType.GET });
};

const fetchProductSubmissionStatuses = async (token: string, productGroupId: number, submissionStatus: SubmissionStatus) => {
  const url = buildUrl(`/product-group/${productGroupId}/submission`, basePath, `submissionStatus=${submissionStatus}`);

  return handleRequest({ token, url, requestType: RequestType.GET });
};

const fetchProductForms = async (token: string, search: string | null, limit?: number) => {
  let url = buildUrl(`/product/form`, basePath, buildQuery(search, limit));

  return handleRequest({ token, url, requestType: RequestType.GET });
};

const fetchProductHistory = async (id: string, token: string) => {
  const url = buildUrl(`/product/${id}/history`, basePath);

  return handleRequest({ token, url, requestType: RequestType.GET });
};

const retirePublishedProduct = async (token: string, productGroupId: Id, productId: Id, dataSet: unknown) => {
  const url = buildUrl(`/product-group/${productGroupId}/product/${productId}/retirement`, basePath);

  return handleRequest({ token, url, requestType: RequestType.POST, data: dataSet });
};


export {
  fetchMaNumbersConfig,
  fetchProductsTree,
  fetchCompanyProducts,
  fetchProductSubmissionStatuses,
  fetchActiveIngredientsSearch,
  fetchProductForms,
  fetchUnitsOfMeasurement,
  fetchProductHistory,
  retirePublishedProduct,
};
