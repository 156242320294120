import React from 'react';
import { useLocation, useParams } from 'react-router';
import { useFetchSubmission } from '@common/features/submission/hooks/useFetchSubmission';
import useAppNavigation from '../../routing/useAppNavigation';
import { DocumentDetailsParentContent } from '@common/features/document/pages/ContentDetails/DocumentDetailsParentContent';
import { DocumentType } from '@common/types';
import { DocumentDetailsReadonlySection } from '@common/features/document/components/DocumentContentDetails/types';
import { useFetchSubmissionTaskByDocument } from '@common/features/submission/hooks/useFetchSubmissionTaskByDocument';
import DocumentDetailsSkeletonLoader from '@common/features/document/pages/ContentDetails/DocumentDetailsSkeletonLoader';
import { getDocumentProccessingDetails } from '@common/features/document/helpers';

interface ISubmissionContentDetails {
  contentType: DocumentType;
  readonly?: boolean;
}

export const SubmissionContentDetails = ({ contentType, readonly }: ISubmissionContentDetails) => {
  const location = useLocation();
  const state = location.state;
  const { submissionId = '', documentId = '' } = useParams();
  const { submission, isFetchingSubmission, isSubmissionFetched } = useFetchSubmission(submissionId!);

  const { submissionTaskData, isFetchingSubmissionTaskByDocument, isSubmissionTaskByDocumentFetched } =
    useFetchSubmissionTaskByDocument(submissionId, documentId, contentType);

  const { goToProductGroup } = useAppNavigation();

  const redirectToProductGroup = () => {
    goToProductGroup(submissionId!, state);
  };

  const documentDetailsReadonlySections = {
    [DocumentDetailsReadonlySection.documentTitle]: readonly,
  };

  const isEditable = !readonly && submission?.isEditable;

  const isSubmissionDataLoading = !isSubmissionFetched || isFetchingSubmission;
  const isSubmissionTaskDataLoading = !isSubmissionTaskByDocumentFetched || isFetchingSubmissionTaskByDocument;

  if (isSubmissionDataLoading || isSubmissionTaskDataLoading) {
    return <DocumentDetailsSkeletonLoader />;
  }

  const documentProcessingDetails = getDocumentProccessingDetails(submissionTaskData);

  return (
    <DocumentDetailsParentContent
      isEditable={isEditable}
      isFormExtended={true}
      documentDetailsReadonlySections={documentDetailsReadonlySections}
      submission={submission!}
      submissionId={submissionId!}
      documentId={documentId!}
      contentType={contentType}
      isAdmin={false}
      documentProcessingDetails={documentProcessingDetails}
      onCancel={redirectToProductGroup}
      onSuccess={redirectToProductGroup}
    />
  );
};
