import React, { useCallback, useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import { toast } from 'react-toastify';
import { FormProvider } from 'react-hook-form';
import Tabs from '@common/components/Tabs/Tabs';
import SubmissionFlowHeader from '@common/components/SubmissionFlowHeader/SubmissionFlowHeader';
import { ProductHistory } from './tabs/ProductHistory';
import { ProductDetails } from '@components/ProductDetails/ProductDetails';
import { HeaderBreadcrumb, Link } from '@components/SubmissionFlowHeader/styles';
import { AssociatedContent } from './tabs/AssociatedContent';
import { ProductInfo } from './tabs/ProductInfo';
import * as Styled from './tabs/ProductInfo/styles';
import { AssociatedContentReadonlySections, ProductInfoReadonlySections, ProductTabs } from './types';
import {
  getAssociatedProducts,
  getDocumentAfterSelectionChanged,
  getProductType,
  mapErrors,
  prepareUpdateProductDetailsData,
} from './helpers';
import { AlreadyAssociatedProductsModal } from './tabs/AssociatedContent/AlreadyAssociatedProductsModal';
import { useUpdateProductDetails } from './hooks/useUpdateProductDetails';
import { Id } from '@common/types';
import SubmissionFlowFooter from '@common/components/SubmissionFlowFooter/SubmissionFlowFooter';
import { IProductDto, ISubmissionDto } from '@common/features/submission/types';
import { useFeatureFlags } from '@common/hooks/useFeatureFlags';
import { useProductForm, IProductForm } from './hooks/useProductForm';
import { NotifyModal } from '@common/components/NotifyModal';
import { ValidateProductErrors } from '../ValidateProductErrors/ValidateProductErrors';

export const tabsLabelsWrapperStyle: React.CSSProperties = {
  paddingLeft: '40px',
};

const errorIcon = '/icons/small_warning_icon.svg';

interface ISubmissionProductContent {
  product?: IProductDto;
  submission: ISubmissionDto;
  productInfoReadonlySections?: ProductInfoReadonlySections;
  associatedContentReadonlySections?: AssociatedContentReadonlySections;
  isProductRetired?: boolean;
  onCancel: () => void;
  onResultSuccess?: (() => void) | null;
  shouldDisplayAmpIds: boolean;
  shouldDisplayContactGroup: boolean;
}

const SubmissionProductContent = ({
  product,
  submission,
  productInfoReadonlySections = {},
  associatedContentReadonlySections,
  isProductRetired,
  onCancel,
  onResultSuccess,
  shouldDisplayAmpIds,
  shouldDisplayContactGroup,
}: ISubmissionProductContent) => {
  const { state } = useLocation();
  const [selectedTab, setSelectedTab] = useState<string>(state?.tab ?? ProductTabs.ProductInfo);
  const [isValidateOpen, setIsValidateOpen] = useState(false);

  const { showProductHistory } = useFeatureFlags();

  const productId = product?.productId!;
  const submissionId = submission.submissionId;

  const activeIngredients = useMemo(() => product?.activeIngredients ?? [], [product?.activeIngredients]);

  const productType = getProductType(submission?.productGroup.productType);

  const productGroupName = submission?.productGroup.productGroupName ?? '';

  const documents = submission?.documents ?? [];

  const {
    productFormMethods,
    handleSubmit: handleSubmitProductForm,
    formState: { dirtyFields, errors },
    isProductDetailsValid,
    isProductInfoValid,
    isAssociatedContentValid,
    documentIdAfterSelectionChanged,
    draftOrPublishedDocuments,
    showAssociatedContentWarning,
    handleFileOpen,
    handleSelect,
    handleConfirm,
  } = useProductForm({ productGroupName, product, documents, activeIngredients, shouldDisplayAmpIds });

  const documentAfterSelectionChanged = getDocumentAfterSelectionChanged(submission?.documents, documentIdAfterSelectionChanged);

  const associatedProducts = getAssociatedProducts(
    productId,
    submission?.products!,
    documentAfterSelectionChanged?.linkedProductIds!,
  );

  const { updateProductDetails, isUpdatingProductDetails } = useUpdateProductDetails(submissionId, productId);

  const resultActions = {
    onSuccess: () => {
      toast.success('Draft saved');
      if (onResultSuccess) onResultSuccess();
    },
  };

  const handleUpdateProductDetails = () => {
    if (!isUpdatingProductDetails) {
      handleSubmitProductForm(
        (data: IProductForm) => {
          updateProductDetails(prepareUpdateProductDetailsData(data, dirtyFields, product!), resultActions);
        },
        () => setIsValidateOpen(true),
      )();
    }
  };

  const handleCancelValidationModal = () => setIsValidateOpen(false);

  const getItems = useCallback(() => {
    const items = [
      {
        label: ProductTabs.ProductInfo,
        content: (
          <ProductInfo
            product={product!}
            productType={productType}
            activeIngredients={activeIngredients}
            productGroupName={productGroupName}
            showError={!isProductInfoValid}
            productInfoReadonlySections={productInfoReadonlySections}
          />
        ),
        icon: !isProductInfoValid ? errorIcon : undefined,
      },
      {
        label: ProductTabs.AssociatedContent,
        content: (
          <AssociatedContent
            showMissingAssociationWarning={showAssociatedContentWarning}
            showRetiredProductWarning={!!isProductRetired}
            documents={draftOrPublishedDocuments}
            onSelect={handleSelect}
            onFileOpen={(documentId: Id) => handleFileOpen(submissionId, documentId)}
            disabledSections={associatedContentReadonlySections}
          />
        ),
        icon: !isAssociatedContentValid ? errorIcon : undefined,
      },
      {
        label: ProductTabs.ProductDetails,
        content: (
          <ProductDetails shouldDisplayContactGroup={shouldDisplayContactGroup} shouldDisplayAmpIds={shouldDisplayAmpIds} />
        ),
        icon: !isProductDetailsValid ? errorIcon : undefined,
      },
    ];

    if (showProductHistory)
      items.push({
        label: ProductTabs.ProductHistory,
        content: <ProductHistory productId={productId.toString()} />,
        icon: undefined,
      });

    return items;
  }, [
    activeIngredients,
    associatedContentReadonlySections,
    draftOrPublishedDocuments,
    handleFileOpen,
    handleSelect,
    isAssociatedContentValid,
    isProductDetailsValid,
    isProductInfoValid,
    isProductRetired,
    product,
    productGroupName,
    productInfoReadonlySections,
    productType,
    shouldDisplayAmpIds,
    shouldDisplayContactGroup,
    showAssociatedContentWarning,
    showProductHistory,
    submissionId,
    productId,
  ]);

  const mappedErrors = mapErrors(errors);

  return (
    <>
      <Styled.LayoutWrapper>
        <SubmissionFlowHeader title={product?.name} productId={product?.productId}>
          <HeaderBreadcrumb>
            <Link>Product Family</Link> &gt; <Link>Product Group</Link> &gt; Product
          </HeaderBreadcrumb>
        </SubmissionFlowHeader>
        <Styled.ProductWrap>
          <FormProvider {...productFormMethods}>
            <Tabs
              initialTab={state?.tab}
              items={getItems()}
              styles={{
                tabsLabelsWrapper: tabsLabelsWrapperStyle,
              }}
              selectedTab={selectedTab}
              onTabSelected={(selectedTab) => setSelectedTab(selectedTab)}
            />
          </FormProvider>
        </Styled.ProductWrap>
      </Styled.LayoutWrapper>
      <SubmissionFlowFooter onCancel={onCancel} onContinue={handleUpdateProductDetails} continueText="Save changes" />
      <AlreadyAssociatedProductsModal
        isOpen={!!documentIdAfterSelectionChanged && (documentAfterSelectionChanged?.linkedProductIds?.length ?? 0) > 1}
        document={documentAfterSelectionChanged?.documentTitle ?? ''}
        products={associatedProducts}
        onConfirm={handleConfirm}
      />
      <NotifyModal
        isOpen={isValidateOpen}
        title="Please add the following missing information"
        onClose={handleCancelValidationModal}
      >
        <ValidateProductErrors
          productInfoErrors={mappedErrors.productInfoErrors}
          associatedContentErrors={mappedErrors.associatedContentErrors}
          productDetailsErrors={mappedErrors.productDetailsErrors}
        />
      </NotifyModal>
    </>
  );
};

export default SubmissionProductContent;
