import styled from "styled-components";

export const InsufficientPermissionsWrap = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

export const InsufficientPermissionsTop = styled.div`
  height: 100%;
  width: 100%;
  padding: 0 0 0 200px;
  background-image: url(/icons/logo-background.svg);
  background-position: left top;
  background-repeat: no-repeat;
  background-size: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const InsufficientPermissionsLogo = styled.img`
  width: 300px;
  margin: 0 0 15px 0;
`;

export const InsufficientPermissionsText = styled.div`
  margin: 0 0 30px 0;
`;

export const InsufficientPermissionsTextHeader = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.xxLarge};
  font-weight: bold;
  color: #231F20;
`;

export const InsufficientPermissionsTextRegular = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.large};
  color: #5D5B5C;

  p {
    margin: 10px 0;
  }
`;

export const InsufficientPermissionsTextButtons = styled.div`
  button {
    width: 160px;
  }
`;
