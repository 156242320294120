import React from 'react';
import { HtmlContentModalParams } from './types';
import { NotifyModal } from '@common/components/NotifyModal';
import * as SharedStyled from '@common/styles';
import { useThirdPartyLinkWarning } from '@common/features/global/ThirdPartyLinkWarning/useThirdPartyLinkWarning';

interface IHtmlContentModalProps {
  onClose: () => void;
  modalParams?: HtmlContentModalParams;
  width?: string;
}

export const HtmlContentModal = ({ modalParams, onClose, width }: IHtmlContentModalProps) => {
  const { handleUrlClick } = useThirdPartyLinkWarning();

  const handleClick = (e: React.MouseEvent) => {
    const clickedElement = e.target as HTMLAnchorElement;
    if (clickedElement.tagName === 'A') {
      handleUrlClick(e, clickedElement.href);
    }
  };

  return (
    <NotifyModal
      noIcon={true}
      onClose={onClose}
      title={modalParams?.title}
      isOpen={!!modalParams}
      width={width ?? '32rem'}
      zIndex={998}
      className="html-modal"
    >
      <SharedStyled.HtmlView>
        <div onClick={handleClick} dangerouslySetInnerHTML={{ __html: modalParams?.content ?? '' }} />
      </SharedStyled.HtmlView>
    </NotifyModal>
  );
};
