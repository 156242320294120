import { useNavigate } from 'react-router';
import { paths, routes } from './routes';
import { DocumentType, Id } from '@common/types';
import { globalRoutes } from '@common/routing/routes';

type State = undefined | {};

export type BackToContentTypeState = State & {
  contentType: DocumentType;
};

const useAppNavigation = () => {
  const navigate = useNavigate();

  const goToHome = (state: State = undefined) => {
    navigate(globalRoutes.home, { state: state });
  };

  const goToProducts = (state: State = undefined) => {
    navigate(routes.products, { state: state });
  };

  const goToSubmissions = (state: State = undefined) => {
    navigate(routes.submissions, { state: state });
  };

  const goToAudits = (state: State = undefined) => {
    navigate(routes.audits, { state: state });
  };

  const goToDrafts = (state: State = undefined) => {
    navigate(routes.drafts, { state: state });
  };

  const goToCreateSubmission = (state: State = undefined) => {
    navigate(routes.createSubmission, { state: state });
  };

  const goToSubmissionTaskDetails = (id: string, submissionTaskId: string, state: State = undefined) => {
    navigate(`${routes.submissions}/${id}/${paths.submissionTasks}/${submissionTaskId}`, { state: state });
  };

  const goToProductGroup = (id: Id, state: State = undefined) => {
    navigate(`${routes.submissions}/${id}`, { state: state });
  };

  const goToDocumentContentDetails = (id: string, contentType: DocumentType, documentId: string, state: State = undefined) => {
    navigate(`${routes.submissions}/${id}/${contentType.toLowerCase()}/${documentId}`, { state: state });
  };

  const goToReadOnlyDocumentContentDetails = (
    id: string,
    contentType: DocumentType,
    documentId: string,
    state: State = undefined,
  ) => {
    navigate(`${routes.submissions}/${id}/${contentType.toLowerCase()}/${documentId}/view`, { state: state });
  };

  const goToUpdateDocumentContentDetails = (
    id: string,
    contentType: DocumentType,
    documentId: string,
    state: State = undefined,
  ) => {
    navigate(`${routes.submissions}/${id}/${contentType.toLowerCase()}/${documentId}/update`, { state: state });
  };

  const goToUpdateTaskDocumentContentDetails = (
    id: Id,
    contentType: DocumentType,
    documentId: Id,
    submissionTaskId: string,
    state: State = undefined,
  ) => {
    navigate(
      `${routes.submissions}/${id}/${
        paths.submissionTasks
      }/${submissionTaskId}/${contentType.toLowerCase()}/${documentId}/update`,
      { state: state },
    );
  };

  const goToSubmissionTaskProductDetails = (id: Id, productId: Id, submissionTaskId: Id, state: State = undefined) => {
    navigate(`${routes.submissions}/${id}/${paths.submissionTasks}/${submissionTaskId}/product/${productId}`, { state: state });
  };

  const goToReadOnlySubmissionTaskProductDetails = (id: Id, productId: Id, submissionTaskId: Id, state: State = undefined) => {
    navigate(`${routes.submissions}/${id}/${paths.submissionTasks}/${submissionTaskId}/product/${productId}/view`, { state: state });
  };

  const goToPublishedDocumentContentDetails = (
    productGroupId: string,
    contentType: DocumentType,
    documentId: string,
    state: State = undefined,
  ) => {
    navigate(`${routes.productGroups}/${productGroupId}/${contentType.toLowerCase()}/${documentId}`, { state: state });
  };

  const goToProduct = (id: Id, productId: Id, state: State = undefined) => {
    navigate(`${routes.submissions}/${id}${routes.product}/${productId}`, { state: state });
  };

  const goToReadOnlyProduct = (id: Id, productId: Id, state: State = undefined) => {
    navigate(`${routes.submissions}/${id}${routes.product}/${productId}/view`, { state: state });
  };

  const goToPublishedProduct = (id: string, productId: string, state: State = undefined) => {
    navigate(`${routes.productGroups}/${id}${routes.product}/${productId}`, { state: state });
  };

  const goToPublishedProductGroup = (id: string, state: State = undefined) => {
    navigate(`${routes.productGroups}/${id}`, { state: state });
  };

  const goToHtml = (submissionId: string, submissionTaskId: string, state: State = undefined) => {
    navigate(`${routes.submissions}/${submissionId}/${paths.submissionTasks}/${submissionTaskId}/${paths.html}`, {
      state: state,
    });
  };

  const goBack = () => {
    navigate(-1);
  };

  return {
    goToHome,
    goToProducts,
    goToSubmissions,
    goToAudits,
    goToDrafts,
    goToCreateSubmission,
    goToSubmissionTaskDetails,
    goToProductGroup,
    goToDocumentContentDetails,
    goToPublishedDocumentContentDetails,
    goToProduct,
    goToPublishedProductGroup,
    goToUpdateDocumentContentDetails,
    goToPublishedProduct,
    goToHtml,
    goBack,
    goToUpdateTaskDocumentContentDetails,
    goToSubmissionTaskProductDetails,
    goToReadOnlyDocumentContentDetails,
    goToReadOnlyProduct,
    goToReadOnlySubmissionTaskProductDetails,
  };
};

export default useAppNavigation;
