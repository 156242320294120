import React from 'react';
import { SlaTableWrap, SlaName, SlaValue, SlaDisclaimer, AdditionalInfoCell } from './styles';
import { Link } from '../styles';
import { Button, ButtonTypes } from '@common/components';
import DashboardSection from '../DashboardSection/DashboardSection';
import { Sla } from './types';

interface ISlaSection {
  slaItems: Array<Sla>;
}

const SlaSection = ({ slaItems }: ISlaSection) => {
  const hasDeadlines = slaItems.some((i) => !!i.deadlines);

  return (
    <DashboardSection>
      <SlaTableWrap>
        <h2>SLA Information</h2>        
        <table>
          <tbody>
          {hasDeadlines && (
              <tr>
                <AdditionalInfoCell>
                  <h3>In order to get your content published before Christmas or New Year, please submit before the below dates</h3>
                </AdditionalInfoCell>
                <td />
                  <td colSpan={2}>
                    <h3>Submission deadline</h3>
                  </td>
              </tr>
            )}
            {hasDeadlines && (
              <tr>
                <td />
                <td />
                <SlaValue>Christmas</SlaValue>
                <SlaValue>New Year</SlaValue>
              </tr>
            )}
            {slaItems.map(({ name, value, deadlines }) => (
              <tr key={name}>
                <SlaName>{name}</SlaName>
                <SlaValue>{value}</SlaValue>
                {deadlines?.map((d) => (
                  <SlaValue>{d}</SlaValue>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </SlaTableWrap>
      <SlaDisclaimer>
        Datapharms' SLA's have been designated while taking into consideration the MHRA 10-day best practice guidance for
        publishing medicines information{' '}
        <Link
          target="_blank"
          href="https://s3.eu-west-2.amazonaws.com/datapharm/dms/documents/Guide_to_MHRA_10_Day_Calculation.pdf"
        >
          learn more
        </Link>
      </SlaDisclaimer>
      <Button
        type={ButtonTypes.PRIMARY_MEDIUM_BORDER}
        height={50}
        width={210}
        text="View Content Policy"
        onClick={() => window.open('/documents/contentPolicy.pdf')}
      />
    </DashboardSection>
  );
};

export default SlaSection;
