import styled, { css } from 'styled-components';
import { ButtonTypes } from './types';
import { StyledSVG } from '@common/styles';

const primaryStyles = css`
  color: ${({ theme }) => theme.elements.defaultWhite};
  background-color: ${({ theme }) => theme.elements.primary};
  border: 1px solid ${({ theme }) => theme.elements.primary};
`;

const dropDownButtonStyles = css`
  color: ${({ theme }) => theme.elements.primaryMedium};
  background-color: ${({ theme }) => theme.colors.defaultWhite};
  border: 1px dashed ${({ theme }) => theme.elements.primaryMedium};
  margin: -1px 0 0 0;
  border-collapse: collapse;
  border-radius: 0px;
`;

const primaryBorderStyles = css`
  color: ${({ theme }) => theme.elements.primary};
  border: 1px solid ${({ theme }) => theme.elements.primary};
`;

const borderContentStyles = css<IButtonWrap>`
  display: flex;
  justify-content: space-between;
  border: 1px solid ${({ borderColor }) => borderColor};
  white-space: nowrap;
  border-radius: 10px;

  .arrowPostfix {
    align-self: right;
  }

  :hover {
    border: 1px solid ${({ borderHoverColor }) => borderHoverColor};
  }
`;

const succesStyles = css`
  color: ${({ theme }) => theme.elements.primary};
  background-color: ${({ theme }) => theme.elements.success};
  border: 1px solid ${({ theme }) => theme.elements.success};
`;

const dashedBorderStyles = css`
  color: ${({ theme }) => theme.colors.darkGrey};
  background-color: ${({ theme }) => theme.colors.defaultWhite};
  border: 1px dashed ${({ theme }) => theme.colors.mediumGrey};
`;

const primaryMediumBorderStyles = css`
  color: ${({ theme }) => theme.elements.primaryMedium};
  border: 2px solid ${({ theme }) => theme.elements.primaryMedium};
`;

const disabledStyles = css`
  opacity: 0.5;
  background-color: ${({ theme }) => theme.colors.superlightGrey};
  pointer-events: none;
`;

const textStyles = css`
  color: ${({ theme }) => theme.elements.textGrey};
  padding: 0;
  width: auto;
`;

const BUTTON_STYLES_TYPES = {
  [ButtonTypes.PRIMARY]: primaryStyles,
  [ButtonTypes.PRIMARY_BORDER]: primaryBorderStyles,
  [ButtonTypes.BORDER_CONTENT]: borderContentStyles,
  [ButtonTypes.SUCCESS]: succesStyles,
  [ButtonTypes.DASHED_BORDER]: dashedBorderStyles,
  [ButtonTypes.TEXT]: textStyles,
  [ButtonTypes.PRIMARY_MEDIUM_BORDER]: primaryMediumBorderStyles,
  [ButtonTypes.DISABLED]: disabledStyles,
  [ButtonTypes.DROPDOWNBUTTON]: dropDownButtonStyles,
};

interface IButtonWrap {
  renderType: ButtonTypes;
  height?: number;
  width?: number;
  minWidth?: number;
  icon?: boolean;
  color?: string;
  borderColor?: string;
  borderHoverColor?: string;
  isLoading?: boolean;
}

export const StyledButton = styled.button<IButtonWrap>`
  font-weight: 600;
  font-size: ${({ theme }) => theme.elementFontSizes.button};
  height: ${({ height }) => (height ? height : 50)}px;
  width: ${({ width }) => width && width}px;
  min-width: ${({ minWidth }) => (minWidth ? minWidth : 60)}px;
  padding: 15px ${({ icon }) => (icon ? 5 : 22)}px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: ${({ isLoading }) => (isLoading ? 'default' : 'pointer')};
  background-color: transparent;
  ${({ renderType }) => BUTTON_STYLES_TYPES[renderType]};
  color: ${({ color, isLoading, theme }) => (isLoading ? theme.colors.darkBlue : color ?? '')};
  ${({ disabled }) => disabled && BUTTON_STYLES_TYPES.DISABLED}
`;

export const ContentWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .contentIcon {
    margin: 0px 10px;
  }
`;

export const Icon = styled(StyledSVG)`
  margin-right: 5px;
  min-width: 25px;
`;

export const SpinnerBackground = styled.div`
  height: 30px;
  width: 30px;
  position: absolute;

  .spinner svg {
    position: absolute;
    height: 30px;
    width: 30px;
    animation: rotation 2s infinite ease;
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
