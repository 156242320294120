import { SubmissionsListFiltersFormFields } from '../types';
import { useFilters } from '@components/FiltersDropdown/useFilters';
import { ICheckboxEntry } from '@common/types';
import { submissionStatusCheckboxes } from '../constants';
import { submissionTaskStatusCustomerOptions } from '@common/features/submissionTask/constants';
import { useSubmissionTaskTypes } from '@common/features/submissionTask/hooks/useSubmissionTaskTypes';
import { FilterStateId } from '@common/constants';

export const useSubmissionsListFilters = (onConfirmFilters: () => void) => {
  const submissionTaskTypesOptions = useSubmissionTaskTypes();
  const initialValues = {
    [SubmissionsListFiltersFormFields.fastTrack]: null,
    [SubmissionsListFiltersFormFields.from]: null,
    [SubmissionsListFiltersFormFields.to]: null,
    [SubmissionsListFiltersFormFields.statuses]: [],
    [SubmissionsListFiltersFormFields.tasksStatuses]: [],
    [SubmissionsListFiltersFormFields.contentTypes]: [],
  };

  const {
    isFiltersOpen,
    dirtyValues,
    filtersQueryString,
    handleConfirmFilters,
    handleCancelFilters,
    handleToggleFilters,
    handleClearAllFilters,
    handleClear,
    setCheckboxValue,
    setNonCheckboxValue,
  } = useFilters(initialValues, initialValues, FilterStateId.Submissions, [
    SubmissionsListFiltersFormFields.statuses,
    SubmissionsListFiltersFormFields.tasksStatuses,
    SubmissionsListFiltersFormFields.contentTypes,
  ]);

  const appliedFiltersCount = [
    dirtyValues[SubmissionsListFiltersFormFields.statuses].length > 0,
    dirtyValues[SubmissionsListFiltersFormFields.contentTypes].length > 0,
    dirtyValues[SubmissionsListFiltersFormFields.tasksStatuses].length > 0,
    dirtyValues[SubmissionsListFiltersFormFields.fastTrack] != null,
    !!dirtyValues[SubmissionsListFiltersFormFields.from] || !!dirtyValues[SubmissionsListFiltersFormFields.to],
  ].filter((x) => x === true).length;

  const handleConfirm = () => {
    handleConfirmFilters();
    onConfirmFilters();
  };

  return {
    filtersQueryString,
    filtersDropdownProps: {
      isOpen: isFiltersOpen,
      appliedFiltersCount,
      onConfirm: handleConfirm,
      onCancel: handleCancelFilters,
      onClearAll: handleClearAllFilters,
      onToggle: handleToggleFilters,
    },
    submissionStatusFilterProps: {
      label: 'Submission status',
      options: submissionStatusCheckboxes,
      values: dirtyValues[SubmissionsListFiltersFormFields.statuses],
      onChange: (item: ICheckboxEntry) => setCheckboxValue(SubmissionsListFiltersFormFields.statuses, item),
      onClear: () => handleClear([SubmissionsListFiltersFormFields.statuses], []),
    },
    dateFilterProps: {
      label: 'Submission date',
      fromValue: dirtyValues[SubmissionsListFiltersFormFields.from],
      toValue: dirtyValues[SubmissionsListFiltersFormFields.to],
      onFromChange: (...event: any[]) => setNonCheckboxValue(SubmissionsListFiltersFormFields.from, event[0]),
      onToChange: (...event: any[]) => setNonCheckboxValue(SubmissionsListFiltersFormFields.to, event[0]),
      onClear: () => handleClear([SubmissionsListFiltersFormFields.from, SubmissionsListFiltersFormFields.to], null),
    },
    fastTrackFilterProps: {
      value: dirtyValues[SubmissionsListFiltersFormFields.fastTrack],
      label: 'Fast track',
      onChange: (...event: any[]) => setNonCheckboxValue(SubmissionsListFiltersFormFields.fastTrack, event[0]),
      onClear: () => handleClear([SubmissionsListFiltersFormFields.fastTrack], null),
    },
    taskStatusFilterProps: {
      values: dirtyValues[SubmissionsListFiltersFormFields.tasksStatuses],
      label: 'Task status',
      options: submissionTaskStatusCustomerOptions,
      onChange: (item: ICheckboxEntry) => setCheckboxValue(SubmissionsListFiltersFormFields.tasksStatuses, item),
      onClear: () => handleClear([SubmissionsListFiltersFormFields.tasksStatuses], []),
    },
    contentTypeFilterProps: {
      values: dirtyValues[SubmissionsListFiltersFormFields.contentTypes],
      label: 'Content type',
      options: submissionTaskTypesOptions,
      onChange: (item: ICheckboxEntry) => setCheckboxValue(SubmissionsListFiltersFormFields.contentTypes, item),
      onClear: () => handleClear([SubmissionsListFiltersFormFields.contentTypes], []),
    },
  };
};
