import { useState } from 'react';

export const useUnableToUpdateAudioVideoModal = () => {
  const [isUnableToUpdateAudioVideoModalOpen, setUnableToUpdateAudioVideoModalOpen] = useState(false);

  const closeUnableToUpdateAudioVideoModal = () => {
    setUnableToUpdateAudioVideoModalOpen(false);
  };

  const openUnableToUpdateAudioVideoModal = () => {
    setUnableToUpdateAudioVideoModalOpen(true);
  };

  return {
    isUnableToUpdateAudioVideoModalOpen,
    closeUnableToUpdateAudioVideoModal,
    openUnableToUpdateAudioVideoModal,
  };
};
