import { createColumnHelper } from '@tanstack/react-table';
import React from 'react';

import { Button, ButtonTypes } from '@common/components';
import { useCustomSorting } from '@common/components/DatapharmTable/useCustomSorting';
import { SubmissionActions } from '@common/components/SubmissionActions';
import { SubmissionStatus } from '@common/components/SubmissionStatus';
import { SubmissionTaskActions } from '@common/components/SubmissionTaskActions';
import { SubmissionTaskStatus } from '@common/components/SubmissionTaskStatus';
import { TitleWeblinkCell } from '@common/features/document/components/TitleWeblinkCell';
import { ICompanySubmission } from '@common/features/submission/types';

import { formatDate, getSubmissionTaskTypeDisplayValue, getTaskIcon, isStringNullOrEmpty } from '@common/helpers';
import { SubmissionStatus as SubmissionStatusType, SubmissionTaskStatuses } from '@common/services/types';
import { AllowedSubmissionActions, CustomerAllowedTaskActions, DocumentType, Id, SubmissionTaskType } from '@common/types';

import { getDocumentAttributes } from '@common/features/document/helpers';
import { StyledSVG } from '@common/styles';
import * as Styled from './styles';

interface ISubmissionProps {
  onCancel: (submissionId: number) => void;
  onRemove: (submissionId: number) => void;
  onSubmissionDetailsClick: (submissionId: number, status: SubmissionStatusType, existingProductGroupId?: number) => void;
}

export const useSubmissionsTableSorting = () => {
  var columnTranslations = {
    submissionTitle: 'Title',
    status: 'Status',
    duration: 'Duration',
    submittedAt: 'DateSubmitted',
    submittedBy: 'SubmittedBy',
  };

  return useCustomSorting<ICompanySubmission>(columnTranslations);
};

export const prepareSubmissionsColumns = ({ onCancel, onSubmissionDetailsClick, onRemove }: ISubmissionProps) => {
  const columnHelper = createColumnHelper<Omit<ICompanySubmission, 'expandableContent'>>();

  return [
    columnHelper.accessor('submissionTitle', {
      header: 'Submission ID_title',
    }),
    columnHelper.accessor('status', {
      header: 'Status',
      cell: ({ getValue }) => <SubmissionStatus value={getValue() as SubmissionStatusType} />,
    }),
    columnHelper.accessor('duration', {
      header: 'Days in progress',
      cell: ({ getValue }) => `${getValue()} days`,
    }),
    columnHelper.accessor('submittedAt', {
      header: 'Date Submitted',
      cell: ({ getValue }) => {
        const value = new Date(getValue());

        return value ? formatDate(value) : '-';
      },
    }),
    columnHelper.accessor('submittedBy', {
      header: 'Submitted By',
    }),
    columnHelper.display({
      header: 'Details',
      cell: ({ row }) => (
        <Button
          type={ButtonTypes.TEXT}
          text=""
          icon="/icons/eye.svg"
          height={25}
          onClick={() =>
            onSubmissionDetailsClick(
              row.original.submissionId,
              row.original.status as SubmissionStatusType,
              row.original.existingProductGroupId,
            )
          }
        />
      ),
    }),
    columnHelper.display({
      header: 'Actions',
      cell: ({ row }) => {
        const allowedActions = [];

        if (
          row.original.status === SubmissionStatusType.InProgress ||
          (row.original.status === SubmissionStatusType.Draft && row.original.containsTasks)
        ) {
          allowedActions.push(AllowedSubmissionActions.Cancel);
        }

        if (row.original.status === SubmissionStatusType.Draft && !row.original.containsTasks) {
          allowedActions.push(AllowedSubmissionActions.Remove);
        }

        return (
          <SubmissionActions
            allowedActions={allowedActions}
            onCancel={() => onCancel(row.original.submissionId)}
            onRemove={() => onRemove(row.original.submissionId)}
          />
        );
      },
    }),
  ];
};

export interface ISubmissionTaskTableRow {
  type: SubmissionTaskType;
  documentType: DocumentType;
  name: string;
  version: number;
  title: string;
  fastTrack: boolean;
  status: string;
  sla: string;
  submissionId: number;
  submissionTaskId: number;
  allowedCustomerActions: CustomerAllowedTaskActions[];
  htmlContent: string;
  link: string;
  customerLink: string | null;
  hasDocument: boolean;
}

interface ISubmissionTaskProps {
  onApprove: (taskId: number, submissionId: number, index: number) => void;
  onCancel: (taskId: number, submissionId: number, index: number) => void;
  onRequestChanges: (taskId: number, submissionId: number, index: number) => void;
  onDocumentTaskDetailsClick: (taskId: number, submissionId: number) => void;
  onProductTaskDetailsClick: (submissionId: number) => void;
  onFileOpen: (taskId: Id) => void;
  onViewConvertedContent: (submissionTaskId: string, submissionId: string) => void;
  onViewHtmlContent: (text: string, title: string) => void;
}

export const prepareDetailsColumns = ({
  onApprove,
  onCancel,
  onRequestChanges,
  onDocumentTaskDetailsClick,
  onProductTaskDetailsClick,
  onFileOpen,
  onViewConvertedContent,
  onViewHtmlContent,
}: ISubmissionTaskProps) => {
  const columnHelper = createColumnHelper<ISubmissionTaskTableRow>();

  return [
    columnHelper.accessor('type', {
      header: 'Type',
      cell: ({ getValue, row }) => {
        const taskType = getValue() as SubmissionTaskType;
        const contentType = row.original.documentType;
        return (
          <Styled.TaskType>
            {getSubmissionTaskTypeDisplayValue(taskType, contentType)}
            <StyledSVG height={25} src={getTaskIcon(taskType)} />
          </Styled.TaskType>
        );
      },
    }),
    columnHelper.accessor('name', {
      header: 'Name',
      cell: ({ row, getValue }) => {
        const documentAttributes = getDocumentAttributes(row.original.documentType, false);
        var isAudioVideoWithoutUrl =
          documentAttributes.showAudioVideoWebLink &&
          isStringNullOrEmpty(row.original.link) &&
          isStringNullOrEmpty(row.original.customerLink);

        return (
          <TitleWeblinkCell
            id={row.original.submissionTaskId!}
            text={getValue()}
            htmlContent={row.original.htmlContent}
            disabled={!row.original.hasDocument || isAudioVideoWithoutUrl}
            link={isStringNullOrEmpty(row.original.link) ? row.original.customerLink : row.original.link}
            onViewContent={onViewHtmlContent}
            onFileOpen={onFileOpen}
          />
        );
      },
    }),
    columnHelper.accessor('submissionTaskId', {
      header: 'Task ID',
    }),
    columnHelper.accessor('fastTrack', {
      header: 'Fast track',
      cell: ({ getValue }) =>
        getValue() && (
          <Styled.CenterFlex>
            <StyledSVG src="/icons/red_flag.svg" />
          </Styled.CenterFlex>
        ),
    }),
    columnHelper.accessor('status', {
      header: 'Status',
      cell: ({ getValue }) => <SubmissionTaskStatus value={getValue() as SubmissionTaskStatuses} />,
    }),
    columnHelper.accessor('sla', {
      header: 'SLA',
      cell: ({ getValue }) => `${getValue()} days`,
    }),
    columnHelper.display({
      header: 'Details',
      cell: ({
        row: {
          original: { submissionId, submissionTaskId, type },
        },
      }) => (
        <Button
          type={ButtonTypes.TEXT}
          text=""
          icon="/icons/eye.svg"
          height={25}
          onClick={() =>
            type === SubmissionTaskType.ProductDiscontinue
              ? onProductTaskDetailsClick(submissionId)
              : onDocumentTaskDetailsClick(submissionTaskId, submissionId)
          }
        />
      ),
    }),
    columnHelper.accessor('allowedCustomerActions', {
      id: 'actions',
      header: 'Actions',
      cell: ({
        getValue,
        row: {
          index,
          original: { submissionId, submissionTaskId, type },
        },
      }) => (
        <SubmissionTaskActions
          data-testid={`action-${submissionTaskId}`}
          allowedActions={getValue()}
          onApprove={() => onApprove(submissionTaskId, submissionId, index)}
          onCancel={() => onCancel(submissionTaskId, submissionId, index)}
          onRequestChange={() => onRequestChanges(submissionTaskId, submissionId, index)}
          onViewConvertedContent={() => onViewConvertedContent(submissionTaskId.toString(), submissionId.toString())}
          onReview={() => onDocumentTaskDetailsClick(submissionTaskId, submissionId)}
        />
      ),
    }),
  ];
};
