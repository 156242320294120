import React from 'react';
import { NotifyModal } from '@common/components/NotifyModal';
import { ModalTypes } from '@common/components/NotifyModal/NotifyModal';
import * as Styled from '@common/styles';

interface ICancelCreateSubmissionModal {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

export const CancelCreateSubmissionModal = ({ isOpen, onClose, onConfirm }: ICancelCreateSubmissionModal) => {
  return (
    <NotifyModal
      title="Do you want to cancel and return to the dashboard?"
      width="800px"
      modalType={ModalTypes.warning}
      isOpen={isOpen}
      cancelText="Continue submission"
      onClose={onClose}
      onConfirm={onConfirm}
    >
      <Styled.ModalContentSmall>
        You have unpublished changes which have been saved as a draft. Your drafts can be found in under submissions.
      </Styled.ModalContentSmall>
    </NotifyModal>
  );
};
