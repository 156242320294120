import {
  Button,
  ButtonTypes,
  FormCheckboxesSection,
  HookFormRichTextEditor,
  ModalWithFooterWrapper,
  SideDecorationContent,
} from '@common/components';
import { HookFormCheckbox } from '@common/components/Checkbox/HookFormCheckbox';
import { CheckboxOptionText } from '@common/components/CheckboxOption/styles';
import FormWebLinkInput from '@common/components/WebLinkInput/FormWebLinkInput';
import { UNITS_DIVIDER } from '@common/constants';
import { DocumentProcessingFlow } from '@common/features/document/components/DocumentContentDetails/types';
import { epilConversionCheckboxTexts, getModalContent, pilAutoApproveCheckboxTexts } from '@common/features/document/constants';
import {
  getDocumentAttributes
} from '@common/features/document/helpers';
import { ISecondaryDocumentProps } from '@common/features/document/hooks/useAddSecondaryFile';
import * as Styled from '@common/features/document/styles';
import { AddModalOpenModes, FormFields } from '@common/features/document/types';
import { getDocumentTypeLabel, richTextEditorHttpsValidator } from '@common/helpers';
import { GroupedCheckboxSections, StyledSVG, Title } from '@common/styles';
import { DocumentType, ICheckboxEntry, ValidationError } from '@common/types';
import FormDocumentTitle from '@components/DocumentTitle/FormDocumentTitle';
import FileUpload from '@components/FileUpload/FileUpload';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import ProductsToUpdate from './ProductsToUpdate';

export interface IAddModal {
  type: DocumentType;
  isOpen: AddModalOpenModes;
  isUploading: boolean;
  uploadProgress: number;
  productsMatchingDocument?: ICheckboxEntry[];
  secondaryDocumentProps: ISecondaryDocumentProps;
  documentProcessingFlow: DocumentProcessingFlow;
  onFileSubmit: (files: FileList | null) => void;
  onUploadClick: () => void;
  onCancelClick: () => void;
  onRemove: () => void;
  uploadErrors?: () => ValidationError[];
}

const AddDocumentModal = ({
  type,
  isOpen,
  uploadProgress,
  isUploading,
  productsMatchingDocument = [],
  secondaryDocumentProps,
  documentProcessingFlow,
  onFileSubmit,
  onCancelClick,
  onUploadClick,
  onRemove,
  uploadErrors,
}: IAddModal) => {
  const { setValue, watch } = useFormContext();

  const uploadedFile = watch(FormFields.file);
  const uploadedSecondaryFile = watch(FormFields.secondaryfile);
  const webLink = watch(FormFields.webLink);
  const includeSecondaryDocument = watch(FormFields.includeSecondaryDocument);

  const fileSize = Math.round((uploadedFile?.size ?? 0) / UNITS_DIVIDER);
  const secondaryfileSize = Math.round((uploadedSecondaryFile?.size ?? 0) / UNITS_DIVIDER);

  const handleDocumentTitleClear = () => setValue(FormFields.documentTitle, '');
  const handleWebLinkClear = () => setValue(FormFields.webLink, '');

  const documentAttributes = getDocumentAttributes(type, false);
  const shouldHandleDocTitleUpdate = isOpen !== 'Update';

  const shouldDisableFileInput = (documentAttributes.showRmmWebLink || documentAttributes.showAudioVideoWebLink) && !!webLink;
  const shouldDisableWebLinkInput = !!uploadedFile || watch(FormFields.noAudioVideoUrl);
  const contentTypeLabel = getDocumentTypeLabel(type);
  const documentTitleLabel = documentAttributes.allowHtmlContentUpload ? 'Content title' : 'Document title';

  const [page, setPage] = useState(0);

  if (!type) {
    return null;
  }

  const shouldOfferProducts = () => productsMatchingDocument.length > 1;

  const reset = () => {
    setPage(0);
  };

  const nextPage = () => {
    if (shouldOfferProducts() && page === 0 && uploadErrors && uploadErrors()?.length === 0) setPage(1);
    if (!shouldOfferProducts() || page === 1) onUploadClick();
  };

  const previousPage = () => {
    setPage(0);
  };

  const modalContent = getModalContent(isOpen!, documentProcessingFlow)[type];

  const modalTitleTestId = `${isOpen === 'Add' ? 'add' : 'update'}-${type}-document-modal-title`;

  const toolbarOptions = [[{ header: [1, 2, 3, 4, false] }], ['bold', 'italic', 'underline'], [{ list: 'ordered' }], ['link']];
  const isPrimaryDocument = documentAttributes.showAssociationWarning;

  const secondaryDocumentTemplate = {
    title: modalContent.secondaryUploadTitle,
    subtitle: modalContent.secondaryUploadDescription,
  };

  const renderFooter = () => {
    return (
      <Styled.FooterWrapper>
        <Styled.Bottom>
          <Styled.Left>
            {page === 1 && (
              <StyledSVG
                onClick={() => {
                  previousPage();
                }}
                src={`/icons/back.svg`}
              />
            )}
          </Styled.Left>

          <Styled.Right>
            <Styled.CancelButtonWrap>
              <Button
                onClick={() => {
                  reset();
                  onCancelClick();
                }}
                text="Cancel"
                type={ButtonTypes.TEXT}
              />
            </Styled.CancelButtonWrap>
            <Button
              disabled={isUploading}
              onClick={() => nextPage()}
              width={163}
              text={shouldOfferProducts() && page === 0 ? 'Continue' : 'Upload'}
              type={ButtonTypes.PRIMARY}
              data-testid="document-upload"
            />
          </Styled.Right>
        </Styled.Bottom>
      </Styled.FooterWrapper>
    );
  };

  const handleNoAudioVideoUrlChange = () => {
    setValue(FormFields.webLink, '');
  };

  return (
    <ModalWithFooterWrapper isOpen={!!isOpen} footer={renderFooter()}>
      <Styled.Wrap>
        <Styled.Top>
          <Title data-testid={modalTitleTestId}>{modalContent.title}</Title>
          {page === 0 ? <> {modalContent.description && modalContent.description}</> : null}
        </Styled.Top>
        {page === 0 ? (
          <SideDecorationContent>
            {documentAttributes.allowFileUpload ? (
              <FileUpload
                title={modalContent.fileUploadTitle}
                onFileSubmit={onFileSubmit}
                loadingPresents={uploadProgress}
                isLoading={isUploading}
                fileName={uploadedFile?.name}
                fileSize={fileSize + 'KB'}
                fileInputSubText={modalContent.fileSubText}
                onRemove={onRemove}
                disabled={shouldDisableFileInput}
              />
            ) : null}
            {documentProcessingFlow === DocumentProcessingFlow.qrdPil ? (
              <>
                <Title small>{secondaryDocumentTemplate.title}</Title>
                <p>{secondaryDocumentTemplate.subtitle}</p>
                <GroupedCheckboxSections>
                  <FormCheckboxesSection field={FormFields.includeSecondaryDocument} text={''} />
                </GroupedCheckboxSections>
                {includeSecondaryDocument ? (
                  <FileUpload
                    onFileSubmit={secondaryDocumentProps.handleFileSubmit}
                    loadingPresents={secondaryDocumentProps.uploadProgress}
                    isLoading={secondaryDocumentProps.isLoading}
                    fileSize={secondaryfileSize + 'KB'}
                    fileName={uploadedSecondaryFile?.name}
                    fileInputSubText={modalContent.secondaryfileSubText}
                    onRemove={secondaryDocumentProps.handleRemove}
                    disabled={shouldDisableFileInput}
                  />
                ) : null}
              </>
            ) : null}
            {documentAttributes.showRmmWebLink || documentAttributes.showAudioVideoWebLink ? (
              <div>
                <FormWebLinkInput
                  onClearInput={handleWebLinkClear}
                  fieldName={FormFields.webLink}
                  disabled={shouldDisableWebLinkInput}
                  contentType={type}
                />
                {documentAttributes.showAudioVideoWebLink ? (
                  <Styled.CheckboxWrapper>
                    <HookFormCheckbox name={FormFields.noAudioVideoUrl} onChange={handleNoAudioVideoUrlChange} />
                    <CheckboxOptionText>I am unable to provide a link to the {contentTypeLabel.toLocaleLowerCase()}</CheckboxOptionText>
                  </Styled.CheckboxWrapper>
                ) : null}
              </div>
            ) : null}

            {documentAttributes.allowHtmlContentUpload ? (
              <>
                <Title small>Enter your {contentTypeLabel}</Title>
                <HookFormRichTextEditor
                  formField={FormFields.content}
                  name="html content"
                  toolbarOptions={toolbarOptions}
                  className="addModalEditorWrapper"
                  rules={{
                    validate: richTextEditorHttpsValidator,
                  }}
                  onRawContentChange={(rawContent) =>
                    rawContent !== undefined && rawContent.toString().length > 5001 ? 'Max. length: 5000 characters' : undefined
                  }
                />
              </>
            ) : null}
            {type === DocumentType.Pil && documentProcessingFlow === DocumentProcessingFlow.standard ? (
              <GroupedCheckboxSections>
                <FormCheckboxesSection
                  field={FormFields.hasDigitalVersion}
                  text={epilConversionCheckboxTexts.title}
                  subtextSection={epilConversionCheckboxTexts.subtitle}
                />
                <FormCheckboxesSection field={FormFields.isCustomerApprovalRequired} text={pilAutoApproveCheckboxTexts.title} />
              </GroupedCheckboxSections>
            ) : null}
            {shouldHandleDocTitleUpdate ? (
              <FormDocumentTitle
                onClearInput={handleDocumentTitleClear}
                fieldName={FormFields.documentTitle}
                title={documentTitleLabel}
              />
            ) : null}
          </SideDecorationContent>
        ) : null}
        {productsMatchingDocument.length > 1 && page === 1 ? (
          <SideDecorationContent>
            <ProductsToUpdate isPrimaryDocument={isPrimaryDocument!} data={productsMatchingDocument} />
          </SideDecorationContent>
        ) : null}
      </Styled.Wrap>
    </ModalWithFooterWrapper>
  );
};

export default AddDocumentModal;
