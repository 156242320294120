import { Highlight } from '@common/styles';
import { DocumentType } from '@common/types';
import { AddModalOpenModes } from './types';
import * as Styled from './styles';
import { StyledLink } from '@common/components/Link/styles';
import { DocumentProcessingFlow } from './components/DocumentContentDetails/types';

export const epilConversionCheckboxTexts = {
  title: 'Would you like your PIL to be converted into an ePIL?',
  subtitle: (
    <>
      {`e-PILs are specially formatted PILs, that are accessible to the visually impaired and can be interpreted by screen
  readers. e-PILs do not include any graphics, tables or images. For more info `}
      <Highlight>{`click here >`}</Highlight>
    </>
  ),
};

export const alternativeTextFileCheckboxTexts = {
  title: 'Do any of the images, graphs or tables in this PIL need alternative text?',
  subtitle: (
    <>
      {`If the images, graphs or tables give essential information that is not covered in the text of the PIL, please provide alternative text that can be displayed instead.`}
    </>
  ),
};

export const pilAutoApproveCheckboxTexts = {
  title: 'Do you want your PIL to be sent back to you to approve?',
  subtitle: null,
};

export const FILE_EXTENSIONS = {
  [DocumentType.Pil.toString()]: ['pdf'],
  [DocumentType.Epil.toString()]: ['docx'],
  [DocumentType.Rmm.toString()]: ['pdf'],
  [DocumentType.UserManual.toString()]: ['pdf'],
  [DocumentType.Dhcp.toString()]: ['pdf'],
  [DocumentType.SafetyAlert.toString()]: ['pdf'],
  [DocumentType.ProductInformation.toString()]: ['pdf'],
  [DocumentType.Smpc.toString()]: ['docx'],
};

export const OLD_WORD_FILES_EXTENSIONS = ['doc'];

export const getModalContent = (mode: AddModalOpenModes, documentProcessingFlow: DocumentProcessingFlow) => {
  return {
    [DocumentType.Smpc.toString()]: {
      title: `${mode} an SmPC`,
      fileUploadTitle: 'Upload your SmPC',
      description: (
        <Styled.Description>
          <Styled.DescriptionText>
            Please make sure you have all the details ready, and you have checked that your SmPC:
          </Styled.DescriptionText>
          <Styled.DescriptionList>
            <Styled.DescriptionListItem>does not contain track changes</Styled.DescriptionListItem>
            <Styled.DescriptionListItem>is not password protected</Styled.DescriptionListItem>
            <Styled.DescriptionListItem>
              does not contain the Quality Review Document (QRD) sections as images
            </Styled.DescriptionListItem>
            <Styled.DescriptionListItem>does not contain any text on top of images/graphs or charts</Styled.DescriptionListItem>
            <Styled.DescriptionListItem>does not contain nested tables</Styled.DescriptionListItem>
          </Styled.DescriptionList>
          <Styled.DescriptionSubtext>
            Further information on document acceptance criteria can be found <StyledLink to={'help'}>here &gt;</StyledLink>
          </Styled.DescriptionSubtext>
        </Styled.Description>
      ),
      fileSubText: '.DOCX 3MB max',
    },
    [DocumentType.Pil.toString()]: {
      title: `${mode} a PIL`,
      fileUploadTitle: 'Upload your PIL',
      secondaryUploadTitle: 'Would you like to include a pdf version of your PIL?',
      secondaryUploadDescription:
        'This should be a high quality PDF copy of your PIL that users can print and read it easily, please note that if you do not provide a seperate pdf version we will create one using the word document above',
      description: documentProcessingFlow === DocumentProcessingFlow.qrdPil ? (
        <Styled.Description>
          <Styled.DescriptionText>
            Please make sure you have all the details ready, and you have checked that your PIL:
          </Styled.DescriptionText>
          <Styled.DescriptionList>
            <Styled.DescriptionListItem>is the QRD version submitted to the regulatory authority</Styled.DescriptionListItem>
            <Styled.DescriptionListItem>is not password protected</Styled.DescriptionListItem>
            <Styled.DescriptionListItem>is not a scanned document</Styled.DescriptionListItem>
          </Styled.DescriptionList>
          <Styled.DescriptionSubtext>
            Further information on document acceptance criteria can be found <StyledLink to={'help'}>here &gt;</StyledLink>
          </Styled.DescriptionSubtext>
        </Styled.Description>
      ) : (
        <Styled.Description>
          <Styled.DescriptionText>
            Please make sure you have all the details ready, and you have checked that your PIL:
          </Styled.DescriptionText>
          <Styled.DescriptionList>
            <Styled.DescriptionListItem>is not password protected</Styled.DescriptionListItem>
            <Styled.DescriptionListItem>is not a scanned document</Styled.DescriptionListItem>
            <Styled.DescriptionListItem>is not an artwork version of the PIL</Styled.DescriptionListItem>
            <Styled.DescriptionListItem>is high quality so that users can print and read it easily</Styled.DescriptionListItem>
          </Styled.DescriptionList>
          <Styled.DescriptionSubtext>
            Further information on document acceptance criteria can be found <StyledLink to={'help'}>here &gt;</StyledLink>
          </Styled.DescriptionSubtext>
        </Styled.Description>
      ),
      fileSubText: documentProcessingFlow === DocumentProcessingFlow.qrdPil ? '.DOCX 5MB max' : '.PDF 3MB max',
      secondaryfileSubText: '.PDF 3MB max',
    },
    [DocumentType.Epil.toString()]: {
      title: `Request ePIL`,
      fileUploadTitle: 'Upload your ePIL',
      description: (
        <Styled.Description>
          <Styled.DescriptionText>
            Please make sure you have all the details ready, and you have checked that your ePIL:
          </Styled.DescriptionText>
          <Styled.DescriptionList>
            <Styled.DescriptionListItem>
              is the word (docx) QRD version submitted to the regulatory authority
            </Styled.DescriptionListItem>
            <Styled.DescriptionListItem>is not password protected</Styled.DescriptionListItem>
            <Styled.DescriptionListItem>is not a scanned document</Styled.DescriptionListItem>
            <Styled.DescriptionListItem>is not a different version to the submitted PIL</Styled.DescriptionListItem>
          </Styled.DescriptionList>
          <Styled.DescriptionSubtext>
            Further information on document acceptance criteria can be found <StyledLink to={'help'}>here &gt;</StyledLink>
          </Styled.DescriptionSubtext>
        </Styled.Description>
      ),
      fileSubText: '.DOCX 3MB max',
    },
    [DocumentType.Rmm.toString()]: {
      title: `${mode} a RMM`,
      fileUploadTitle: 'Upload your RMM',
      description: (
        <Styled.Description>
          <Styled.DescriptionText>
            Please make sure you have all the details ready, and you have checked that your RMM:
          </Styled.DescriptionText>
          <Styled.DescriptionList>
            <Styled.DescriptionListItem>is not password protected</Styled.DescriptionListItem>
            <Styled.DescriptionListItem>is not a scanned document</Styled.DescriptionListItem>
            <Styled.DescriptionListItem>
              has no hidden metadata or personal information saved with the document
            </Styled.DescriptionListItem>
            <Styled.DescriptionListItem>is high quality so that users can print and read it easily</Styled.DescriptionListItem>
          </Styled.DescriptionList>
          <Styled.DescriptionSubtext>
            Further information on document acceptance criteria can be found <StyledLink to={'help'}>here &gt;</StyledLink>
          </Styled.DescriptionSubtext>
        </Styled.Description>
      ),
      fileSubText: '.PDF 3MB max',
    },
    [DocumentType.Dhcp.toString()]: {
      title: `${mode} a DHPC`,
      fileUploadTitle: 'Upload your DHPC',
      description: (
        <Styled.Description>
          <Styled.DescriptionText>
            Please make sure you have all the details ready, and you have checked that your DHPC:
          </Styled.DescriptionText>
          <Styled.DescriptionList>
            <Styled.DescriptionListItem>is not password protected</Styled.DescriptionListItem>
            <Styled.DescriptionListItem>is not a scanned document</Styled.DescriptionListItem>
            <Styled.DescriptionListItem>
              has no hidden metadata or personal information saved with the document
            </Styled.DescriptionListItem>
            <Styled.DescriptionListItem>is high quality so that users can print and read it easily</Styled.DescriptionListItem>
          </Styled.DescriptionList>
          <Styled.DescriptionSubtext>
            Further information on document acceptance criteria can be found <StyledLink to={'help'}>here &gt;</StyledLink>
          </Styled.DescriptionSubtext>
        </Styled.Description>
      ),
      fileSubText: '.PDF 3MB max',
    },
    [DocumentType.UserManual.toString()]: {
      title: `${mode} a User Manual`,
      fileUploadTitle: 'Upload your User Manual',
      description: (
        <Styled.Description>
          <Styled.DescriptionText>
            Please make sure you have all the details ready, and you have checked that your User Manual:
          </Styled.DescriptionText>
          <Styled.DescriptionList>
            <Styled.DescriptionListItem>is not password protected</Styled.DescriptionListItem>
            <Styled.DescriptionListItem>is not a scanned document</Styled.DescriptionListItem>
            <Styled.DescriptionListItem>
              has no hidden metadata or personal information saved with the document
            </Styled.DescriptionListItem>
            <Styled.DescriptionListItem>is high quality so that users can print and read it easily</Styled.DescriptionListItem>
          </Styled.DescriptionList>
          <Styled.DescriptionSubtext>
            Further information on document acceptance criteria can be found <StyledLink to={'help'}>here &gt;</StyledLink>
          </Styled.DescriptionSubtext>
        </Styled.Description>
      ),
      fileSubText: '.PDF 3MB max',
    },
    [DocumentType.SafetyAlert.toString()]: {
      title: `${mode} a Safety information`,
      fileUploadTitle: 'Upload your Safety information',
      description: (
        <Styled.Description>
          <Styled.DescriptionSubtext>
            Please enter the description of the Safety alert as you would like it to appear on emc.
          </Styled.DescriptionSubtext>
          <Styled.DescriptionList>
            <Styled.DescriptionListItem>Please ensure that your link starts with https://</Styled.DescriptionListItem>
          </Styled.DescriptionList>
        </Styled.Description>
      ),
      fileSubText: '.PDF 3MB max',
    },
    [DocumentType.ProductInformation.toString()]: {
      title: `${mode} a Product information`,
      fileUploadTitle: 'Upload your Product information',
      description: (
        <Styled.Description>
          <Styled.DescriptionSubtext>
            Please enter the description of the Product information as you would like it to appear on emc.
          </Styled.DescriptionSubtext>
          <Styled.DescriptionList>
            {`This is a free-text box that can be used to advise HCP's and patients of non-promotional information such as stock availability or changes to product packaging (subject to review by our Data Quality Team)`}
          </Styled.DescriptionList>
        </Styled.Description>
      ),
      fileSubText: '.PDF 3MB max',
    },
    [DocumentType.AudioVideo.toString()]: {
      title: `${mode} a Audio/Video`,
      fileUploadTitle: 'Add link to your Audio/Video',
      description: (
        <Styled.Description>
          <Styled.DescriptionText>
            Please make sure you have all the details ready, and you have checked that your Audio/Video:
          </Styled.DescriptionText>
          <Styled.DescriptionList>
            <Styled.DescriptionListItem>link is proper</Styled.DescriptionListItem>
          </Styled.DescriptionList>
          <Styled.DescriptionSubtext>
            Further information on document acceptance criteria can be found <StyledLink to={'help'}>here &gt;</StyledLink>
          </Styled.DescriptionSubtext>
        </Styled.Description>
      ),
    },
    [DocumentType.Audio.toString()]: {
      title: `${mode} Audio`,
      fileUploadTitle: 'Add link to your Audio',
      description: (
        <Styled.Description>
          <Styled.DescriptionText>
            <p>
              We will host the content so we require a link to a location where we can download the file for review and upload to
              our hosting platform.
            </p>
            <br />
            <p>Audio formats accepted:</p>
          </Styled.DescriptionText>
          <Styled.DescriptionList>
            <Styled.DescriptionListItem>WAV</Styled.DescriptionListItem>
            <Styled.DescriptionListItem>MP3</Styled.DescriptionListItem>
            <Styled.DescriptionListItem>MPEG4</Styled.DescriptionListItem>
            <Styled.DescriptionListItem>AIFF</Styled.DescriptionListItem>
          </Styled.DescriptionList>
          <Styled.DescriptionSubtext>
            Further information on content acceptance criteria can be found <StyledLink to={'help'}>here &gt;</StyledLink>
          </Styled.DescriptionSubtext>
        </Styled.Description>
      ),
    },
    [DocumentType.Video.toString()]: {
      title: `${mode} a Video`,
      fileUploadTitle: 'Add link to your Video',
      description: (
        <Styled.Description>
          <Styled.DescriptionText>
            <p>
              We will host the content so we require a link to a location where we can download the file for review and upload to
              our hosting platform.
            </p>
            <br />
            <p>Video formats accepted:</p>
          </Styled.DescriptionText>
          <Styled.DescriptionList>
            <Styled.DescriptionListItem>MP4</Styled.DescriptionListItem>
            <Styled.DescriptionListItem>MOV</Styled.DescriptionListItem>
            <Styled.DescriptionListItem>WMV</Styled.DescriptionListItem>
          </Styled.DescriptionList>
          <Styled.DescriptionSubtext>
            Further information on content acceptance criteria can be found <StyledLink to={'help'}>here &gt;</StyledLink>
          </Styled.DescriptionSubtext>
        </Styled.Description>
      ),
    },
  };
};
