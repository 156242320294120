import React from 'react';
import {
  HeaderDetails,
  HeaderTitle,
  Left,
  SubmissionFlowHeaderWrap,
  Right,
  ExitMarkWrapper,
} from '@common/components/SubmissionFlowHeader/styles';
import { ReactSVG } from 'react-svg';
import { useNavigate } from 'react-router';
import { Tooltip } from '@common/components/tooltip/Tooltip';
import { useIsOverflow } from '@hooks/useIsOverflow';
import { globalRoutes } from '@routing/routes';
import { Button } from '@common/components/Button/Button';
import { ButtonTypes } from '@common/components/Button/types';
import  EmcProductLinkPanel from '@common/components/EmcProductLinkPanel/EmcProductLinkPanel';
import { selectConfiguration } from '@common/features/configuration/selectors';
import { useAppSelector } from '@common/hooks/redux';

interface ISubmissionFlowHeader {
  title?: string;
  children?: React.ReactNode;
  subTitle?: string;
  productId?: number | null;

  onBackClick?: () => void;
  onCloseClick?: () => void;
}

const SubmissionFlowHeader = ({ title, children, subTitle, productId, onBackClick, onCloseClick }: ISubmissionFlowHeader) => {
  const navigate = useNavigate();
  const titleRef = React.useRef<HTMLSpanElement>(null);
  const isTitleOverflow = useIsOverflow(titleRef);

  const handleBackClick = () => {
    onBackClick ? onBackClick() : navigate(-1);
  };

  const handleCloseClick = () => {
    onCloseClick ? onCloseClick() : navigate(globalRoutes.home);
  };

  const breadcrumbs = children;
  const configuration = useAppSelector(selectConfiguration);
  const productUrl = configuration.emcLink + "/product/" + productId;

  return (
    <SubmissionFlowHeaderWrap>
      <Left>
        <Button onClick={handleBackClick} text="< Back" type={ButtonTypes.PRIMARY} />
        <HeaderDetails>
          {breadcrumbs}
          <HeaderTitle data-tooltip-id={'submission-flow-header'} data-tooltip-content={title} ref={titleRef}>
            {title}
          </HeaderTitle>
          <Tooltip hidden={!isTitleOverflow} id={'submission-flow-header'} style={{ maxWidth: '30vw' }} />
          <b>{subTitle}</b>
        </HeaderDetails>
      </Left>
      <Right>
        { productId === undefined ? "" : (<EmcProductLinkPanel productUrl={productUrl}></EmcProductLinkPanel>) }
        <ExitMarkWrapper onClick={handleCloseClick}>
          <ReactSVG src="/icons/exit_mark.svg" />
        </ExitMarkWrapper>
      </Right>
    </SubmissionFlowHeaderWrap>
  );
};

export default SubmissionFlowHeader;
