export type Colors = {
  darkBlue: string;
  mediumBlue: string;
  lightBlue: string;
  darkGreen: string;
  mediumGreen: string;
  lightGreen: string;
  superlightGreen: string;
  darkRed: string;
  lightRed: string;
  darkOrange: string;
  lightOrange: string;
  superdarkGrey: string;
  darkGrey: string;
  mediumGrey: string;
  lightGrey: string;
  superlightGrey: string;
  defaultWhite: string;
  dirtyWhite: string;
  borderGray: string;
  veryLightBlue: string;
  backgroundBlue: string;
  red: string;
  moderateGrey: string;
};

export type Elements = {
  defaultWhite: string;
  background: string;
  primary: string;
  primaryLight: string;
  primaryMedium: string;
  success: string;
  borderGrey: string;
  grey: string;
  textGrey: string;
  textLightGrey: string;
  textDarkGrey: string;
  readonlyInputBackground: string;
  unselectedSidebarEntry: string;
  submissionTypeBackground: string;
  dropdownGrey: string;
  backgroundWhite: string;
  submissionHeading: string;
  submissionSubHeading: string;
  mediumGrey: string;
  customLayoutGrey: string;
  linkBlue: string;
  badgeGrey: string;
  infoBackground: string;
  infoNew: string;
  infoUpdate: string;
  tabBackgroundGrey: string;
  disabledInputBg: string;
  removeRed: string;
  retireOrange: string;
  labelBackground: string;
};

export type FontSizes = {
  tiny: string;
  small: string;
  medium: string;
  large: string;
  xLarge: string;
  xxLarge: string;
};

export type ElementFontSizes = {
  default: string;
  label: string;
  input: string;
  button: string;
  checkbox: string;
  pageTitle: string;
  sectionTitle: string;
  subSectionTitle: string;
  sectionText: string;
  modalTitle: string;
  modalContent: string;
  pageContent: string;
  errorMessage: string;
};

export interface ICheckboxEntry {
  id: Id;
  text: string;
  tooltipText?: string;
  value?: string | number;
}

export type Id = number | string;

export interface IProductTypeSelectOption {
  label: ProductTypeSelectLabels;
  value: ProductTypeSelectValues;
}

export enum ProductTypeSelectValues {
  BRANDED_INNOVATOR = 'branded-innovator',
  GENERIC = 'generic',
  BRANDED_GENERIC = 'branded-generic',
}

export enum ProductTypeSelectLabels {
  BRANDED_INNOVATOR = 'Branded (innovator)',
  GENERIC = 'Generic',
  BRANDED_GENERIC = 'Branded generic',
}

export enum OperationType {
  New,
  Update,
}

export enum ActionType {
  Update,
  EditXml,
  ViewHtml,
  Remove,
  Replace,
}

export enum SubmissionTaskType {
  None = 'None',
  Smpc = 'SmPC',
  SmpcNew = 'SmpcNew',
  SmpcUpdate = 'SmpcUpdate',
  ePIL = 'ePil',
  ePILNew = 'ePILNew',
  QRDEpilNew = 'QRDEpilNew',
  ePILUpdate = 'ePILUpdate',
  Pil = 'PIL',
  PilNew = 'PilNew',
  QRDPilNew = 'QRDPilNew',
  PilUpdate = 'PilUpdate',
  RMM = 'RMM',
  RMMUpdate = 'RMMUpdate',
  RMMNew = 'RMMNew',
  DHPC = 'DHCP',
  DHPCUpdate = 'DHPCUpdate',
  DHPCNew = 'DHPCNew',
  UserManual = 'UserManual',
  UserManualNew = 'UserManualNew',
  UserManualUpdate = 'UserManualUpdate',
  SafetyAlert = 'SafetyAlert',
  SafetyAlertNew = 'SafetyAlertNew',
  SafetyAlertUpdate = 'SafetyAlertUpdate',
  ProductInformation = 'ProductInformation',
  ProductInformationNew = 'ProductInfoNew',
  ProductInformationUpdate = 'ProductInfoUpdate',
  Audio = 'Audio',
  AudioNew = 'AudioNew',
  AudioUpdate = 'AudioUpdate',
  Video = 'Video',
  VideoNew = 'VideoNew',
  VideoUpdate = 'VideoUpdate',
  AudioVideo = 'AudioVideo',
  AudioVideoNew = 'AudioVideoNew',
  AudioVideoUpdate = 'AudioVideoUpdate',
  LiveChat = 'LiveChat',
  ProductGroupUpdate = 'ProductGroupUpdate',
  DocumentRetire = 'DocumentRetire',
  ProductDiscontinue = 'ProductDiscontinue',
  DocumentsRename = 'DocumentsRename',
  DocumentXmlUpdate = 'DocumentXmlUpdate',
  ProductGroupRename = 'ProductGroupRename',
  All = 'All',
}

export enum DocumentType {
  Smpc = 'SmPC',
  Pil = 'PIL',
  Epil = 'ePil',
  Rmm = 'RMM',
  Dhcp = 'DHCP',
  UserManual = 'UserManual',
  SafetyAlert = 'SafetyInfo',
  AudioVideo = 'AudioVideo',
  LiveChat = 'LiveChat',
  ProductInformation = 'ProductInfo',
  Images = 'Images',
  Audio = 'Audio',
  Video = 'Video',
}

export enum DocumentExtensionType {
  Docx = 'Docx',
  Pdf = 'Pdf',
  Xml = 'Xml',
  Html = 'Html',
}

export enum TaskTypes {
  None = 0,
  SmpcNew = 1,
  SmpcUpdate = 2,
  Smpc = SmpcNew | SmpcUpdate,
  PilNew = 4,
  PilUpdate = 8,

  EpilNew = 16,
  EpilUpdate = 32,
  Epil = EpilNew | EpilUpdate,

  eUserManualNew = 16777216,
  eUserManualUpdate = 33554432,

  UserManualNew = 64,
  UserManualUpdate = 128,
  UserManual = UserManualNew | UserManualUpdate,

  RmmNew = 256,
  RmmUpdate = 512,
  Rmm = RmmNew | RmmUpdate,

  SafetyAlertNew = 1024,
  SafetyAlertUpdate = 2048,
  SafetyAlert = SafetyAlertNew | SafetyAlertUpdate,

  ProductInfoNew = 4096,
  ProductInfoUpdate = 8192,
  ProductInfo = ProductInfoNew | ProductInfoUpdate,

  DhcpNew = 16384,
  DhcpUpdate = 32768,
  Dhcp = DhcpNew | DhcpUpdate,

  ProductGroupUpdate = 65536,
  DocumentRetire = 131072,
  ProductDiscontinue = 262144,

  AudioNew = 1048576,
  AudioUpdate = 536870912,
  Audio = AudioNew | AudioUpdate,

  VideoNew = 268435456,
  VideoUpdate = 1073741824,
  Video = VideoNew | VideoUpdate,

  LiveChat = 2097152,
  DocumentsRename = 67108864,
  DocumentXmlUpdate = 134217728,

  Pil = PilNew | PilUpdate,
  All = SmpcNew |
    SmpcUpdate |
    PilNew |
    PilUpdate |
    EpilNew |
    EpilUpdate |
    UserManualNew |
    UserManualUpdate |
    eUserManualNew |
    eUserManualUpdate |
    RmmNew |
    RmmUpdate |
    SafetyAlertNew |
    SafetyAlertUpdate |
    ProductInfoNew |
    ProductInfoUpdate |
    DhcpNew |
    DhcpUpdate |
    ProductGroupUpdate |
    DocumentRetire |
    ProductDiscontinue |
    AudioNew |
    AudioUpdate |
    VideoNew |
    VideoUpdate |
    LiveChat |
    DocumentsRename |
    DocumentXmlUpdate,
}

export interface INewProductSelectOptionValue {
  productId: Id;
  productGroupId: Id;
}

export interface INewProductSelectOption {
  id?: number;
  label: string;
  value: INewProductSelectOptionValue;
  disabled?: boolean;
}

export interface ISelectOption {
  id?: number;
  label: string;
  value: string;
  disabled?: boolean;
  isCustomOption?: boolean;
}

export enum DocumentStatus {
  None = 'None',
  Draft = 'Draft',
  Retired = 'Retired',
  Published = 'Published',
  Submitted = 'Submitted',
  Discontinued = 'Discontinued',
}

export enum SubmissionStatus {
  None = 0,
  Draft = 1,
  InProgress = 2,
  Approved = 4,
  Cancelled = 8,
  Published = 16,
  Completed = Published | Approved | Cancelled,
  Active = InProgress | Draft,
  All = Published | Draft | InProgress | Approved | Cancelled,
}

export enum SubmissionStatusText {
  Draft = 'Draft',
  InProgress = 'In Progress',
  Approved = 'Approved',
  Cancelled = 'Cancelled',
  Published = 'Published',
}

export enum IsTruthySelectItemLabel {
  Blank = '',
  Yes = 'Yes',
  No = 'No',
  YesGluten = 'Yes - gluten free',
  NoGluten = 'No - contains gluten',
  YesLactose = 'Yes - lactose free',
  NoLactose = 'No - contains lactose',
  NotSpecified = 'Not Specified'
}

export enum IsTruthySelectItemValue {
  Blank = '',
  Yes = 'Yes',
  No = 'No',
}

export interface ErrorLog {
  mainError?: string;
  subError?: string;
  itemName?: string;
  itemId: number;
}

export enum CustomerAllowedTaskActions {
  CustomerRequestChanges = 'CustomerRequestChanges',
  CustomerViewConvertedContent = 'CustomerViewConvertedContent',
  CustomerCompletesReview = 'CustomerCompletesReview',
  CustomerCanEditTaskDetails = 'CustomerCanEditTaskDetails',
  CustomerApproves = 'CustomerApproves',
  CustomerCancels = 'CustomerCancels',
}

export enum AllowedSubmissionActions {
  Cancel = 'Cancel',
  Remove = 'Remove',
}

export interface ITaskIdSubmissionId {
  taskId: number;
  submissionId: number;
}

export interface ValidationError {
  message: string;
  subMessage: string;
}
export interface Dictionary<T> {
  [Key: string]: T;
}

export const TaskTypeToDocumentTypeMap: TaskTypeToDocumentType = {
  [SubmissionTaskType.SmpcNew]: DocumentType.Smpc,
  [SubmissionTaskType.SmpcUpdate]: DocumentType.Smpc,
  [SubmissionTaskType.PilNew]: DocumentType.Pil,
  [SubmissionTaskType.QRDPilNew]: DocumentType.Pil,
  [SubmissionTaskType.PilUpdate]: DocumentType.Pil,
  [SubmissionTaskType.ePILNew]: DocumentType.Epil,
  [SubmissionTaskType.QRDEpilNew]: DocumentType.Epil,
  [SubmissionTaskType.ePILUpdate]: DocumentType.Epil,
  [SubmissionTaskType.DHPCNew]: DocumentType.Dhcp,
  [SubmissionTaskType.DHPCUpdate]: DocumentType.Dhcp,
  [SubmissionTaskType.UserManualNew]: DocumentType.UserManual,
  [SubmissionTaskType.UserManualUpdate]: DocumentType.UserManual,
  [SubmissionTaskType.RMMNew]: DocumentType.Rmm,
  [SubmissionTaskType.RMMUpdate]: DocumentType.Rmm,
  [SubmissionTaskType.SafetyAlertNew]: DocumentType.SafetyAlert,
  [SubmissionTaskType.SafetyAlertUpdate]: DocumentType.SafetyAlert,
  [SubmissionTaskType.ProductInformationNew]: DocumentType.ProductInformation,
  [SubmissionTaskType.ProductInformationUpdate]: DocumentType.ProductInformation,
  [SubmissionTaskType.AudioNew]: DocumentType.Audio,
  [SubmissionTaskType.AudioUpdate]: DocumentType.Audio,
  [SubmissionTaskType.VideoNew]: DocumentType.Video,
  [SubmissionTaskType.VideoUpdate]: DocumentType.Video,
};

type TaskTypeToDocumentType = {
  [key in SubmissionTaskType]?: DocumentType;
};

export interface PaginationResult<T> {
  currentPage: number;
  items: T;
  limit: number;
  offset: number;
  totalCount: number;
  totalPages: number;
}

export type Nullable<T> = T | null;

export interface IAnchorParams {
  url: string;
  cleanFileName: string;
  contentType: string;
}

export enum ResponseContentType {
  docx = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  pdf = 'application/pdf',
  csv = 'text/csv',
}

export interface IValidationModalErrors {
  productGroupErrors: ErrorLog[];
  productsErrors: ErrorLog[];
  contentsErrors: ErrorLog[];
  productAssociationErrors: ErrorLog[];
  contentAssociationErrors: ErrorLog[];
  productUniquenessErrors: ErrorLog[];
}
