import { getData } from '../../services/requestService';
import { RootState } from '@common/store';
import { createAsyncThunkWithCustomErrorHandling } from '../helpers';

export const fetchConfiguration = createAsyncThunkWithCustomErrorHandling('configuraiton', async (_, { getState }) => {
  const state = getState() as RootState;
  const token = state.user.token;
  const data = await getData(token, '/configuration', '');

  return data;
});
